import React, { useMemo } from 'react';
import { compose } from 'redux';
import {
  DialogContent,
  MuiThemeProvider,
  FormControl,
  TextField,
  Tooltip,
  Button,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { CustomInput, SimpleTooltip } from '../../../../components';
import { gtinDisplay } from '../../../../common/utils/utils';
import {
  ProductBlockNew,
  ProductBlockNewRight,
  GenerateGtinButton,
} from './PopupNewProduct.styled';
import {
  defaultTheme,
  InputContainer,
  InputIcon,
} from '../../../../common/styles/Common.styled';
import {
  IconError,
  IconInfoTooltip,
  IconPreloader,
  IconValid,
  IconDropDownAuto,
} from '../../../../common/icons/customIcons';
import { Autocomplete } from '@material-ui/lab';
import PopupNewProductItem from './PopupNewProductItem';
import { ProductGtinBlock } from './styled/PopupNewProduct.styled';
import { ProductRegNumerBlock } from './styled/PopupNewProduct.styled';

import { PackInfoItemMax } from '../Product/Product.styled';
import { CertSelectAuto } from '../../../../components/certificateSelect/CertificateSelect.styled';
import _, { concat } from 'lodash';

const PopupDialogContent = ({
  profileStatusData,
  activeKzDictionary,
  company,
  subaccountData,
  t,
  disabledGTIN,
  handleChange,
  editingIsPermitted,
  onManufacturerImporterSelectChange,
  manufacturerChecked,
  manufacturerFields,
  producerFields,
  producerChecked,
  onProducerSelectChange,
  updateConsumerItem,
  handleErrors,
  onValidateCensorship,
  sendSubaccount,
  addItem,
  deleteItem,
  importerChecked,
  importerFields,
  technicalMode,
  fieldsFilled,
  data,
  gtinValidation,
  regNumberValidation,
  releaseFormList,
  errors,
  gtin,
  CatId,
  currentCatName,
  countries,
  isAccountCanCreateGtin,
  isAvailabilityCreationGtin,
  generatedGtin,
  modalGcp,
  btnGtinTooltipTitle,
  isMarkGroupCategory
}) => {
  const currentAccountList = useMemo(() => {
    const profileObjectsWithGcp = _.filter(profileStatusData, (item) => {
      return item.type === 0;
    });

    const currentAccountList = [];

    if (profileObjectsWithGcp.length) {
      _.forEach(profileObjectsWithGcp, (item) => {
        currentAccountList.push({ ...company, ...item });
      });
    } else {
      currentAccountList.push(company);
    }

    return currentAccountList;
  }, [profileStatusData, company]);

  const makeUniqueSubaccountList = () => {
    const newSubaccountData = _.cloneDeep(subaccountData);
    _.forEach(newSubaccountData, (accountObject) => {
      accountObject.uniqueId = _.uniqueId('account_');
    });

    return newSubaccountData;
  };

  const uniqueSubaccountList = makeUniqueSubaccountList();

  const getOptions = () =>
    concat(
      {
        type: 'manually',
        path: `${t('Заполнить вручную')}`,
      },
      _.map(currentAccountList, (item) =>
        item.gcp
          ? {
              type: 'currentProfile',
              path: `${item.name}, ${item.inn}, ${item.gcp}`,
            }
          : {
              type: 'currentProfile',
              path: `${item.name}, ${item.inn}`,
            },
      ),
      _.map(uniqueSubaccountList, (i) =>
        i && i.GCP
          ? {
              type: i.AccountId,
              path: `${i.Name}, ${i.ITN}, ${i.GCP}`,
              uniqueId: i.uniqueId,
            }
          : {
              type: i.AccountId,
              path: `${i.Name}, ${i.ITN}`,
              uniqueId: i.uniqueId,
            },
      ),
    );

  const getListSubaccount = () =>
    concat(
      _.map(currentAccountList, (item) =>
        item.gcp
          ? {
              type: 'currentProfile',
              name: item.name,
              inn: item.inn,
              gcp: item.gcp,
            }
          : {
              type: 'currentProfile',
              name: item.name,
              inn: item.inn,
            },
      ),
      _.map(uniqueSubaccountList, (i) =>
        i && i.GCP
          ? {
              type: i.AccountId,
              name: i.Name,
              inn: i.ITN,
              gcp: i.GCP,
              uniqueId: i.uniqueId,
            }
          : {
              type: i.AccountId,
              name: i.Name,
              inn: i.ITN,
              uniqueId: i.uniqueId,
            },
      ),
    );

  const handleChangeManufacturer = (_, item) => {
    onManufacturerImporterSelectChange(
      item && item.type,
      'manufacturer',
      item,
      uniqueSubaccountList,
    );
  };

  /**
   * для корректного вывода информации о компании в поле автокомплита
   *
   * @param {*} name string, название компании
   * @param {*} inn string, inn компании
   * @param {*} gcp string, gcp компании, может и не быть у компаний.
   */
  const concatNameInnGcp = (name, gcp, inn) => {
    if (_.isEmpty(name) && _.isEmpty(inn) && _.isEmpty(inn)) {
      return '';
    }
    if (_.isEmpty(gcp)) {
      return `${name}, ${inn}`;
    }
    return `${name}, ${gcp}, ${inn}`;
  };

  const arrayInnBusy = [];
  const innBusy = (value) => {
    if (value.editable) {
      if (
        _.some(uniqueSubaccountList, (i) => i.ITN === value.inn) ||
        _.some(currentAccountList, (i) => i.ITN === value.inn) ||
        value.inn === company.inn
      )
        return arrayInnBusy.push(value);
    }
  };

  const arrayGcpInnBusy = [];
  const gspInnBusy = (value) => {
    const arrayGcpBusy = [];
    if (value.editable) {
      if (
        _.some(
          uniqueSubaccountList,
          (i) => i.GCP === value.gcp && i.ITN === value.inn,
        ) ||
        _.some(
          currentAccountList,
          (i) => i.GCP === value.gcp && i.ITN === value.inn,
        ) ||
        (value.gcp === company.gcp && value.inn === company.inn)
      )
        return arrayGcpInnBusy.push(value);
    }
  };

  const isReadySubmit = () => {
    if (generatedGtin) {
      return fieldsFilled() && gtinValidation.successed;
    } else {
      const gtinValid = gtinValidation.successed && data.gtin;
      return fieldsFilled() && gtinValid;
    }
  };

  const isReadyRegNumber = () =>    isReadySubmit() && isMarkGroupCategory;
  
    return (
    <DialogContent>
      <div>
        <PackInfoItemMax>
          <FormControl fullWidth={true}>
            <MuiThemeProvider theme={CertSelectAuto}>
              <Autocomplete
                options={getOptions()}
                autoHighlight
                onChange={handleChangeManufacturer}
                getOptionLabel={(item) => item.path}
                popupIcon={<IconDropDownAuto />}
                disabled={disabledGTIN || !editingIsPermitted}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Выберите владельца идентификатора товара (GTIN)')}
                    variant="standard"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      value: concatNameInnGcp(
                        manufacturerFields[0].name,
                        manufacturerFields[0].gcp,
                        manufacturerFields[0].inn,
                      ),
                      autoComplete: 'disabled',
                    }}
                  />
                )}
                noOptionsText={t('Нет совпадений')}
              />
            </MuiThemeProvider>
          </FormControl>
        </PackInfoItemMax>

        {manufacturerChecked && (
          <>
            {_.map(manufacturerFields, (value, index) => (
              <MuiThemeProvider key={value.id} theme={defaultTheme}>
                <PopupNewProductItem
                  typeId={index}
                  key={value.id}
                  data={value}
                  isInnBusy={innBusy(value)}
                  isGcpInnBusy={gspInnBusy(value)}
                  updateItem={updateConsumerItem}
                  handleErrors={handleErrors}
                  onValidate={onValidateCensorship}
                  updateData={() => {}}
                  validCbk={() => {}}
                  disabledGtin={disabledGTIN}
                  sendSubaccount={sendSubaccount}
                  isHasAddItemButton={false}
                  blockType={'manufacturer'}
                  t={t}
                  countries={countries[0]}
                />
              </MuiThemeProvider>
            ))}
          </>
        )}
      </div>
      <div>
        <PackInfoItemMax>
          <FormControl fullWidth={true}>
            <MuiThemeProvider theme={CertSelectAuto}>
              <Autocomplete
                options={getOptions()}
                autoHighlight
                onChange={(_, item) =>
                  onProducerSelectChange(
                    item && item.type,
                    0,
                    item,
                    uniqueSubaccountList,
                  )
                }
                disableClearable={producerFields.length !== 1}
                getOptionLabel={(item) => item.path}
                popupIcon={<IconDropDownAuto />}
                disabled={disabledGTIN || !editingIsPermitted}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Выберите производственную площадку')}
                    variant="standard"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      value:
                        producerFields.length !== 0
                          ? concatNameInnGcp(
                              producerFields[0].name,
                              producerFields[0].gcp,
                              producerFields[0].inn,
                            )
                          : null,
                      autoComplete: 'disabled',
                    }}
                  />
                )}
                noOptionsText={t('Нет совпадений')}
              />
            </MuiThemeProvider>
          </FormControl>
        </PackInfoItemMax>

        {producerChecked && (
          <MuiThemeProvider theme={defaultTheme}>
            {_.map(producerFields, (value, index) => (
              <PackInfoItemMax key={index}>
                <FormControl fullWidth={true}>
                  {index !== 0 && (
                    <MuiThemeProvider theme={CertSelectAuto}>
                      <Autocomplete
                        options={getOptions()}
                        autoHighlight
                        onChange={(_, item) =>
                          onProducerSelectChange(
                            item && item.type,
                            index,
                            item,
                            uniqueSubaccountList,
                          )
                        }
                        getOptionLabel={(item) => item.path}
                        disableClearable={
                          index !== 0 ||
                          (index === 0 && producerFields.length !== 1)
                        }
                        popupIcon={<IconDropDownAuto />}
                        disabled={disabledGTIN || !editingIsPermitted}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              label={t('Выберите производственную площадку')}
                              variant="standard"
                              fullWidth
                              inputProps={{
                                ...params.inputProps,
                                value: concatNameInnGcp(
                                  producerFields[index].name,
                                  producerFields[index].gcp,
                                  producerFields[index].inn,
                                ),
                                autoComplete: 'disabled',
                              }}
                            />
                          );
                        }}
                        noOptionsText={t('Нет совпадений')}
                      />
                    </MuiThemeProvider>
                  )}
                  <PopupNewProductItem
                    typeId={index}
                    key={value.id}
                    data={value}
                    isInnBusy={innBusy(value)}
                    isGcpInnBusy={gspInnBusy(value)}
                    updateItem={updateConsumerItem}
                    handleErrors={handleErrors}
                    onValidate={onValidateCensorship}
                    updateData={() => {}}
                    validCbk={() => {}}
                    disabledGtin={disabledGTIN}
                    sendSubaccount={sendSubaccount}
                    addItem={() => addItem('producer')}
                    deleteItem={(id) => deleteItem(id, 'producer')}
                    isHasAddItemButton={producerFields.length - 1 === index}
                    blockType={'producer'}
                    t={t}
                    countries={countries[1]}
                  />
                </FormControl>
              </PackInfoItemMax>
            ))}
          </MuiThemeProvider>
        )}
      </div>
      <div>
        <PackInfoItemMax>
          <FormControl fullWidth={true}>
            <MuiThemeProvider theme={CertSelectAuto}>
              <Autocomplete
                options={getOptions()}
                autoHighlight
                onChange={(_, item) =>
                  onManufacturerImporterSelectChange(
                    item && item.type,
                    'importer',
                    item,
                    uniqueSubaccountList,
                  )
                }
                getOptionLabel={(item) => item.path}
                popupIcon={<IconDropDownAuto />}
                disabled={disabledGTIN || !editingIsPermitted}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Выберите участника оборота')}
                    variant="standard"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      value: concatNameInnGcp(
                        importerFields[0].name,
                        importerFields[0].gcp,
                        importerFields[0].inn,
                      ),
                      autoComplete: 'disabled',
                    }}
                  />
                )}
                noOptionsText={t('Нет совпадений')}
              />
            </MuiThemeProvider>
          </FormControl>
        </PackInfoItemMax>

        {importerChecked && (
          <>
            {_.map(importerFields, (value, index) => (
              <MuiThemeProvider key={value.id} theme={defaultTheme}>
                <PopupNewProductItem
                  typeId={index}
                  key={value.id}
                  data={value}
                  isInnBusy={innBusy(value)}
                  isGcpInnBusy={gspInnBusy(value)}
                  updateItem={updateConsumerItem}
                  handleErrors={handleErrors}
                  onValidate={onValidateCensorship}
                  updateData={() => {}}
                  validCbk={() => {}}
                  disabledGtin={disabledGTIN}
                  sendSubaccount={sendSubaccount}
                  addItem={() => addItem('importer')}
                  deleteItem={(id) => deleteItem(id, 'importer')}
                  isHasAddItemButton={importerFields.length - 1 === index}
                  blockType={'importer'}
                  t={t}
                  countries={countries[2]}
                />
              </MuiThemeProvider>
            ))}
          </>
        )}
      </div>
      {/**
       * GTIN учавстующий в создании consumer package;
       */}
      {!technicalMode && (
        <ProductBlockNew>
          <ProductGtinBlock>
            <MuiThemeProvider theme={defaultTheme}>
              <InputContainer>
                <CustomInput
                  id="gtin"
                  name="gtin"
                  type="text"
                  maxLength={13}
                  required={editingIsPermitted}
                  disabled={
                    disabledGTIN || !fieldsFilled() || arrayInnBusy.length > 0
                  }
                  label={
                    !data.gtin
                      ? t('Введите код упаковки')
                      : errors.gtin
                      ? errors.gtin
                      : !_.isEmpty(gtinValidation.message)
                      ? t('Введите корректный код упаковки')
                      : t('Код упаковки')
                  }
                  onUpdate={() => {}}
                  onChange={(value) =>
                    handleChange('gtin', value, getListSubaccount())
                  }
                  value={
                    disabledGTIN || !editingIsPermitted
                      ? gtinDisplay(_.defaultTo(gtin, ''))
                      : _.defaultTo(gtin, '')
                  }
                  error={!!errors.gtin || !_.isEmpty(gtinValidation.message)}
                  valid={gtinValidation.successed}
                />
                {errors.gtin ? (
                  <InputIcon>
                    <IconError />
                  </InputIcon>
                ) : !_.isEmpty(gtinValidation.message) ? (
                  SimpleTooltip(gtinValidation.message, <IconError />)
                ) : gtinValidation.process ? (
                  <InputIcon>
                    <IconPreloader />
                  </InputIcon>
                ) : gtinValidation.successed ? (
                  <InputIcon>
                    <IconValid />
                  </InputIcon>
                ) : (
                  SimpleTooltip(
                    t('Код товара состоит из 8,12 или 13 цифр'),
                    <IconInfoTooltip />,
                  )
                )}
              </InputContainer>
            </MuiThemeProvider>
          </ProductGtinBlock>
          {isAccountCanCreateGtin && (
            <ProductBlockNewRight>
              <MuiThemeProvider theme={GenerateGtinButton}>
                <Tooltip title={!fieldsFilled() ? '' : btnGtinTooltipTitle}>
                  <span>
                    <Button
                      disabled={
                        disabledGTIN ||
                        !fieldsFilled() ||
                        !isAvailabilityCreationGtin ||
                        !!gtin ||
                        !!generatedGtin ||
                        arrayInnBusy.length > 0
                      }
                      onClick={modalGcp}>
                      {t('Сгенерировать код упаковки')}
                    </Button>
                  </span>
                </Tooltip>
              </MuiThemeProvider>
            </ProductBlockNewRight>
          )}
        </ProductBlockNew>
      )}
      <MuiThemeProvider theme={CertSelectAuto}>
        <Autocomplete
          options={activeKzDictionary}
          autoHighlight
          onChange={(_, item) => {
            handleChange('CatId', item ? item.id : '');
            handleChange('currentCatName', item ? item.name : '');
          }}
          getOptionLabel={(item) => item.name}
          popupIcon={<IconDropDownAuto />}
          disabled={!isReadySubmit()}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('Код государственного классификатора')}
              variant="standard"
              fullWidth
              inputProps={{
                ...params.inputProps,
                autoComplete: 'disabled',
              }}
            />
          )}
          noOptionsText={t('Нет совпадений')}
        />
      </MuiThemeProvider>
      {!technicalMode && isReadyRegNumber() && (
        <ProductBlockNew>
          <ProductRegNumerBlock>
            <MuiThemeProvider theme={defaultTheme}>
              <InputContainer>
                <CustomInput
                  id="regNumber"
                  name="regNumber"
                  type="text"
                  maxLength={14}
                  required={isReadyRegNumber()}
                  disabled={!isReadyRegNumber()} //тут проверка "Код государственного классификатора 'CatId'"
                  label={
                    !data.regNumber
                      ? t('Введите регистрационный номер')
                      : errors.regNumber
                      ? errors.regNumber
                      : !_.isEmpty(regNumberValidation.message)
                      ? t('Введите корректный регистрационный номер')
                      : t('Регистрационный номер')
                  }
                  onUpdate={() => {}}
                  onChange={(value) => handleChange('regNumber', value)}
                  value={data.regNumber}
                  // {
                  //   disabledGTIN || !editingIsPermitted
                  //     ? gtinDisplay(_.defaultTo(gtin, ''))
                  //     : _.defaultTo(gtin, '')
                  // }
                  error={
                    !!errors.regNumber ||
                    !_.isEmpty(regNumberValidation.message)
                  }
                  valid={regNumberValidation.successed}
                />

                {errors.regNumber ? (
                  <InputIcon>
                    <IconError />
                  </InputIcon>
                ) : !_.isEmpty(regNumberValidation.message) ? (
                  SimpleTooltip(regNumberValidation.message, <IconError />)
                ) : regNumberValidation.process ? (
                  <InputIcon>
                    <IconPreloader />
                  </InputIcon>
                ) : regNumberValidation.successed ? (
                  <InputIcon>
                    <IconValid />
                  </InputIcon>
                ) : (
                  SimpleTooltip(
                    t('Регистрационный номер состоит от 12 до 16 символов'), // TODO
                    <IconInfoTooltip />,
                  )
                )}
              </InputContainer>
            </MuiThemeProvider>
          </ProductRegNumerBlock>
        </ProductBlockNew>
      )}
      {isReadyRegNumber() && !!releaseFormList && !!releaseFormList.length && (
        <MuiThemeProvider theme={CertSelectAuto}>
          <Autocomplete
            options={releaseFormList}
            autoHighlight
            onChange={(_, value) => handleChange('releaseForm', value)}
            getOptionLabel={(item) => item}
            popupIcon={<IconDropDownAuto />}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('Форма выпуска')}
                variant="standard"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'disabled',
                }}
              />
            )}
            noOptionsText={t('Нет совпадений')}
          />
        </MuiThemeProvider>
      )}
    </DialogContent>
  );
};

const mapStateToProps = (state) => ({
  activeKzDictionary: state.catalogReducer.activeKzDictionary,
  profileStatusData: state.profileStatusReducer.data,
  subaccountData: state.subAccountReducer.subaccountsForGoodsCreation,
  isAvailabilityCreationGtin: state.productReducer.isAvailabilityCreationGtin,
  generatedGtin: state.productReducer.generatedGtin,
});
      
    export default compose(
  withTranslation(),
  connect(mapStateToProps),
)(PopupDialogContent);
