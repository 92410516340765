import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { waterfall } from 'async';
import cx from 'classnames';
import _ from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  withStyles,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { subtractNotNegative } from '../../../../common/utils/utils';
import {
  IconClose,
  IconError,
  IconInfoTooltip,
  IconNewProductError,
  IconPreloader,
  IconValid,
  IconValidMin,
  IconDropDownAuto,
} from '../../../../common/icons/customIcons';
import { CustomInput, SimpleTooltip } from '../../../../components';
import {
  gtinValidLength,
  packageGtinValidLength,
  packageGtinDisplay,
} from '../../../../common/utils/utils';
import { throwNotification } from '../../../../common/structure';
import PopupNewGtin from '../PopupNewGtin';
import GcpModal from '../GcpModal';
import MultipleAndTransportItem from './MultipleAndTransportItem';
import PopupDialogContent from './PopupNewProductDialogContent';
import {
  loadGtinsByPackageDictionary,
  loadActiveKzDictionary,
  clearGtinsListByPackage,
  loadGtinsListForValidation,
  } from '../../../../common/catalog/Catalog.action';
import {
  createConsumerPackaging,
  loadModal,
  validateGtin,
  validateGroupAndTransportGtin,
  validateUnregistredGtin,
  clearProductData,
  loadTechnicalOptions,
  loadTechnicalCategories,
  createTechnicalPackaging,
  validateTechnicalCard,
  loadCountries,
  checkAccountCanCreateGtin,
  loadAccountGcpList,
  checkAvailabilityCreationGtin,
  clearAvailabilityCreationGtin,
  clearGeneratedGtin,
  registrationNumber,
  clearReleaseFormList,
  markGroupCategory,
} from '../../ducks/Product.actions';
import { loadProfileInfo } from '../../../profile/ducks/Profile/Profile.actions';
import { loadProfileStatus } from '../../../profile/ducks/ProfileStatus/ProfileStatus.actions';
import {
  loadSubAccount,
  sendSubAccount,
} from '../../../profile/ducks/SubAccount/SubAccount.actions';
import {
  DialogProductTitleContent,
  NewProductDialog,
} from '../Product/Product.styled';
import { CertSelectAuto } from '../../../../components/certificateSelect/CertificateSelect.styled';
import {
  defaultTheme,
  DialogTitleStyled,
  InputContainer,
  InputIcon,
  NewCloseBtns,
  DialogTitleOfContentStyled,
  DialogTitleOfContentStyledWithMargin,
} from '../../../../common/styles/Common.styled';
import {
  GenerateGtinButton,
  ModalInfoRequired,
  ModalInfoRequiredValid,
  ProductBlockNew,
  ProductBlockNewLeft,
  ProductBlockNewRight,
} from './PopupNewProduct.styled';
import CopyProductAlert from './CopyProductAlert';
import PopupNewTechnicalCard from './PopupNewTechnicalCard';
import PopupNewTechnicalUnmarkedCard from './PopupNewTechnicalUnmarkedCard';
// import { isReadyRegNumber } from './PopupNewProductDialogContent.js';
import { RedirectDialog, RedirectIconHolder } from '../../../../components/redirectModal/RedirectModal.styled';

const styles = () => ({
  customWidth: {
    maxWidth: 300,
  },
});

const getDefaultTo = (obj, path, defaultValue = '') =>
  _.defaultTo(_.get(obj, path, defaultValue), defaultValue);

function checkRequiredFields(data, technicalMode) {
  const tnved = !data.tnvedId;
  const gtin = technicalMode ? false : !gtinValidLength(data.gtin);
  return !_.some([tnved, gtin]);
}

const WAIT_INTERVAL = 1000;

const initFields = {
  id: _.uniqueId('nestedArray_'),
  nestedPackagingCode: '',
  count: '',
};

const initGroupFields = {
  arrayData: [initFields],
  nestedUnregisteredData: [initFields],
};

const initItem = {
  typeId: 0,
  inn: '',
  name: '',
  gcp: null,
  direction: {
    address: '',
    country: '',
  },
  isActiveSelect: false,
  accountId: null,
};
class PopupNewProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      manufacturerFields: [{ ...initItem, id: _.uniqueId('manufacturerId_') }],
      currentOptionForManufacturer: '',
      producerFields: [{ ...initItem, id: _.uniqueId('producerId_') }],
      currentOptionForProducer: '',
      importerFields: [{ ...initItem, id: _.uniqueId('importerId_') }],
      currentOptionForImporter: '',
      suggestions: {
        tnveds: [],
      },
      data: {
        gtin: '',
        tnvedId: '',
        tnvedTitle: '',
        CatId: '',
        regNumber: '',
        releaseForm: '',
        //count: '',
      },     
      isModalOpen: false,
      isMarkGroupCategory: false,
      // releaseFormList: [],
      currentCatName: '',
      needClean: {
        tnvedId: false,
      },
      errors: {},
      openModalNewGtin: false,
      catIdValidation: {
        process: false,
        message: '',
        successed: false,
      },
      gtinValidation: {
        process: false,
        message: '',
        successed: false,
      },
      regNumberValidation: {
        process: false,
        message: '',
        successed: false,
      },
      cache: {
        tnveds: [],
      },
      goodDraftId: null,
      isCopyGood: false,
      status: null,
      buttonSaveDisable: false,
      buttonCloseDisable: false,
      fieldDisabled: {
        tnvedId: false,
      },
      nestedArray: [initFields],
      groupPackageFields: initGroupFields,
      nestedUndescribedCount: 0,
      editingIsPermitted: true,
      showCopyAlert: false,
      openGcpModal: false,
      btnGtinTooltipTitle: '',
      listSubaccount: [],
    };
    this.validateGtinLengthDebounce = _.debounce(
      this.validateGtinLength,
      WAIT_INTERVAL,
    );
    this.validateRegNumberDebounce = _.debounce(
      this.validateRegNumber,
      WAIT_INTERVAL,
    );
    this.liftBanOnSavingDebounce = _.debounce(this.banOnSaving, WAIT_INTERVAL);
    console.log(props);
  }

  componentDidMount() {
    const {
      onLoadProfileInfo,
      productId,
      selectOptions,
      modalType,
      profileStatusData,
      subaccountData,
      activeKzDictionary,
      gtinsListWasLoaded,
      gtinsForValidationWasLoaded,
      gtinsListForValidation,
      productData,
      technicalOptions,
      technicalCategories,
      countries,
    } = this.props;
    waterfall(
      [
        (cbk) => onLoadProfileInfo(cbk),
        (cbk) => {
          if (!_.isNaN(productId)) {
            this.loadModal(productId, cbk);
          } else {
            cbk(null);
          }
        },
      ],
      (e) => {},
    );

    if (
      _.isEmpty(selectOptions) &&
      modalType === 'group' &&
      !gtinsListWasLoaded
    ) {
      this.props.loadGtinsByPackageDictionary('consumer');
    }
    if (
      _.isEmpty(gtinsListForValidation) &&
      modalType === 'group' &&
      !gtinsForValidationWasLoaded
    ) {
      this.props.loadValidationList();
    }
    if (
      _.isEmpty(selectOptions) &&
      modalType === 'transport' &&
      !gtinsListWasLoaded
    ) {
      this.props.loadGtinsByPackageDictionary('transport');
    }
    if (_.isEmpty(profileStatusData)) {
      this.props.onLoadProfileStatus();
    }
    if (_.isEmpty(subaccountData)) {
      this.props.onLoadSubAccountData();
    }
    if (_.isEmpty(activeKzDictionary)) {
      this.props.loadActiveKzDictionary();
    }
    if (!_.isEmpty(productData)) {
      this.props.clearProductData();
    }
    if (_.isEmpty(technicalOptions) && modalType === 'technical') {
      this.props.loadTechnicalOptions();
    }
    if (_.isEmpty(technicalCategories) && modalType === 'technical') {
      this.props.loadTechnicalCategories();
    }
    if (_.isEmpty(countries)) {
      this.props.loadCountries();
    }

    this.props.checkAccountCanCreateGtin();
  }

  addNewSubaccount = () => {
    const {
      manufacturerFields,
      producerFields,
      importerFields,
      listSubaccount,
    } = this.state;
    const allFields = _.concat(
      manufacturerFields,
      producerFields,
      importerFields,
    );
    for (var i = 0; i < allFields.length; i++) {
      let haveNewSubaccounts = _.isEmpty(
        listSubaccount.filter(
          (subaccount) =>
            subaccount.inn + subaccount.gcp ===
            allFields[i].inn + allFields[i].gcp,
        ),
      );
      if (haveNewSubaccounts && allFields[i].manually) {
        this.sendSubaccount(allFields[i]);
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { generatedGtin, isAccountCanCreateGtin, modalType } = this.props;
    const {
      manufacturerFields,
      groupPackageFields,
      errors,
      nestedArray,
      gtinValidation,
    } = this.state;

    if (
      modalType === 'unit' &&
      gtinValidation.successed &&
      gtinValidation.successed !== prevState.gtinValidation.successed
    ) {
      this.addNewSubaccount();
    }

    if (
      isAccountCanCreateGtin !== prevProps.isAccountCanCreateGtin &&
      isAccountCanCreateGtin
    ) {
      this.props.loadAccountGcpList();
    }

    // проверка на валидацию сгенерированного кода GTIN (для подстраховки согласно описанию задачи 402)
    if (generatedGtin !== prevProps.generatedGtin) {
      this.checkGtin(generatedGtin);
    }
    //  вызов метода проверки введенных данных, на основании которых генерируется новый код GTIN.
    //  Определяет кликабельность кнопки "Сгенерировать код упаковки".
    if (isAccountCanCreateGtin && modalType === 'unit') {
      if (manufacturerFields[0].gcp !== prevState.manufacturerFields[0].gcp) {
        this.checkAvailabilityCreationGtin(
          'consumer',
          manufacturerFields[0].gcp,
        );
      }
    }

    if (isAccountCanCreateGtin && modalType === 'group') {
      const currentListRegistered = _.compact(
        _.map(groupPackageFields.arrayData, 'nestedPackagingCode'),
      );
      const prevListRegistered = _.compact(
        _.map(prevState.groupPackageFields.arrayData, 'nestedPackagingCode'),
      );
      const currentListUnregistered = _.compact(
        _.map(groupPackageFields.nestedUnregisteredData, 'nestedPackagingCode'),
      );
      const prevListUnregistered = _.compact(
        _.map(
          prevState.groupPackageFields.nestedUnregisteredData,
          'nestedPackagingCode',
        ),
      );
      const currentList = currentListRegistered.concat(currentListUnregistered);
      const prevList = prevListRegistered.concat(prevListUnregistered);
      if (
        (!_.isEqual(currentList, prevList) &&
          _.isEqual(currentListUnregistered, prevListUnregistered)) ||
        (errors.unregisteredArrayErrros !==
          prevState.errors.unregisteredArrayErrros &&
          errors.unregisteredArrayErrros === false) ||
        (!_.isEqual(currentListUnregistered, prevListUnregistered) &&
          errors.unregisteredArrayErrros ===
            prevState.errors.unregisteredArrayErrros &&
          errors.unregisteredArrayErrros === false)
      ) {
        this.checkAvailabilityCreationGtin('group', '', currentList);
      }
    }
    if (isAccountCanCreateGtin && modalType === 'transport') {
      const currentGtinListTransport = _.compact(
        _.map(nestedArray, 'nestedPackagingCode'),
      );
      const prevGtinListTransport = _.compact(
        _.map(prevState.nestedArray, 'nestedPackagingCode'),
      );
      if (!_.isEqual(currentGtinListTransport, prevGtinListTransport)) {
        this.checkAvailabilityCreationGtin(
          'transport',
          '',
          currentGtinListTransport,
        );
      }
    }
  }

  componentWillUnmount() {
    this.validateGtinLengthDebounce.cancel();
    this.liftBanOnSavingDebounce.cancel();
    this.props.clearGtinsListByPackage();
    this.props.clearGeneratedGtin();
    this.props.clearAvailabilityCreationGtin();
  }

  innBusy = (value) => {
    return value.inn === this.props.profile.company.inn;
  };

  updateConsumerItem = (id, fieldData, typeId) => {
    const regexp = /\D+/; // костыль. надо вырезать префикс который добавили с помощью _.uniqueId()
    const switchId = id.match(regexp);

    switch (switchId[0]) {
      case 'manufacturerId_':
        const manufacturerData = _.cloneDeep(this.state.manufacturerFields);
        manufacturerData[typeId] = _.assign(
          {},
          manufacturerData[typeId],
          fieldData,
        );
        return this.setState({ manufacturerFields: manufacturerData });
      case 'producerId_':
        const producerData = _.cloneDeep(this.state.producerFields);
        producerData[typeId] = _.assign({}, producerData[typeId], fieldData);
        return this.setState({ producerFields: producerData });
      case 'importerId_':
        const importerData = _.cloneDeep(this.state.importerFields);
        importerData[typeId] = _.assign({}, importerData[typeId], fieldData);
        return this.setState({ importerFields: importerData });
      default:
        return;
    }
  };

  handleErrors = (id, error) => {
    this.setState((prevState) => ({
      errors: { ...prevState.errors, [id]: error },
    }));
  };

  onValidateCensorship = (uidField, errorText) => {
    this.setState((prevState) => {
      const booleanValue = !!errorText;
      const censorshipErrors = booleanValue
        ? { ...prevState.censorshipErrors, [uidField]: booleanValue }
        : _.omit(prevState.censorshipErrors, [uidField]);
      return { censorshipErrors };
    });
  };

  banOnSaving = (status = false) => {
    if (this.state.buttonSaveDisable !== status) {
      this.setState({ buttonSaveDisable: status });
    }
    if (status) {
      this.liftBanOnSavingDebounce();
    }
  };

  onValidateCensorship = (uidField, errorText) => {
    this.setState((prevState) => ({
      errors: { ...prevState.errors, [uidField]: !!errorText },
    }));
  };

  AllNeedClean = (flag = false) =>
    _.mapValues(this.state.needClean, () => flag);

  loadModal = (id, cbk = () => null) => {
    this.setState({ buttonSaveDisable: true });
    this.props.loadModal(id, (error, response) => {
      if (_.isNil(error)) {
        const tnvedId = getDefaultTo(response, 'tnvedId');

        this.setState((prevState) => ({
          buttonSaveDisable: false,
          data: {
            ...prevState.data,
            gtin: getDefaultTo(response, 'gtin'),
            draftId: getDefaultTo(response, 'draftId', null),
            tnvedId: tnvedId,
            tnvedTitle: _.defaultTo(
              _.find(this.state.cache.tnveds, { id: tnvedId }),
              { path: '' },
            ).path,
          },
          needClean: {
            tnvedId: !!tnvedId,
          },
          gtinValidation: {
            ...prevState.gtinValidation,
            successed: !_.isEmpty(response.gtin),
          },
          fieldDisabled: {
            ...prevState.fieldDisabled,
            tnvedId: !!tnvedId,
          },
        }));
      } else {
        this.setState({ buttonSaveDisable: false });
        cbk(error);
      }
    });
  };

  setDefaultNeedClean = () =>
    this.setState({ needClean: this.AllNeedClean(false) });

  checkCatId = (value) => {
    this.setState(
      (prevState) => ({
        data: { ...prevState.data, CatId: value },
        errors: { ...prevState.errors, CatId: false },
        catIdValidation: { message: '', successed: false, process: false },
      }),
      this.validateCatId,
    );
  };

  checkOptionId = (value) => {
    this.setState(
      (prevState) => ({
        data: { ...prevState.data, option: value },
        errors: { ...prevState.errors, option: false },
        catIdValidation: { message: '', successed: false, process: false },
      }),
      this.validateCatId,
    );
  };

  validateCatId = () => {
    const { t, modalType, throwError } = this.props;
    const { gtin, optionId, CatId, count } = this.state.data;
    const { nestedArray } = this.state;
    const groupAndTransportData = _.cloneDeep(nestedArray);
    const filteredNestedArray = _.forEach(
      groupAndTransportData,
      (arrayItem) => {
        delete arrayItem.id;
      },
    );

    const data = {
      optionId: optionId,
      CatId: CatId,
      gtin: gtin,
      count: 1,
    };

    this.props.validateTechnicalCard(data, (errors, successed, response) => {
      // const goodDraftId = getDefaultTo(response, 'goodDraftId', null);
      // const isCopyGood = getDefaultTo(response, 'isCopyGood', false);
      // const status = getDefaultTo(response, 'status', null);
      // const createExemplar = getDefaultTo(response, 'continue', false);
      // if (createExemplar) {
      //   return this.setState({
      //     showCopyAlert: true,
      //   });
      // }

      // if (!_.isNil(goodDraftId)) {
      //   const fieldDisabledAll = _.mapValues(
      //     this.state.fieldDisabled,
      //     () => true,
      //   );

      //   if (isCopyGood) {
      //     // Создания экземпляра карточки импортного товара
      //     throwError(`
      //                     ${t(
      //                       'Карточка с таким Кодом товара уже зарегистрирована в Каталоге',
      //                     )}.
      //                     ${t(
      //                       'Заполните полное наименование товара и перейдите в полную форму карточки, ' +
      //                         'нажав кнопку «Подтвердить»',
      //                     )}
      //                 `);
      //     this.setState({ fieldDisabled: { ...fieldDisabledAll } });
      //   } else {
      //     const text = `${t(
      //       'Внимание! Код товара зарегистрирован в Каталоге',
      //     )}.
      //                     ${t(
      //                       'Вы можете продолжить внесение данных в открытом окне или, нажав «Подтвердить», ' +
      //                         'в случае, если все поля окна заполнены',
      //                     )}`;

      //     if (status === 'draft') {
      //       throwError(text);
      //       this.setState({ fieldDisabled: fieldDisabledAll });
      //     }

      //     if (status === 'require_changes') {
      //       throwError(text);
      //     }
      //   }

      //   this.loadModal(goodDraftId);
      // }

      this.setState({
        catIdValidation: {
          successed: successed,
          message: getDefaultTo(errors, 'error.catId'),
          process: false,
        },
        // goodDraftId,
        // isCopyGood,
        // status,
      });
    });
    return;
  };

  checkRegNumber = (value) => {
    this.setState(
      (prevState) => ({
        data: { ...prevState.data, regNumber: value },
        errors: { ...prevState.errors, regNumber: false },
        regNumberValidation: { message: '', successed: false, process: false },
      }),
      this.validateRegNumberDebounce,
    );
  };

  validateRegNumber = async () => {
    const { t, registrationNumber } = this.props;
    const { regNumber } = this.state.data;
  
    if (regNumber.length < 12 || regNumber.length > 16) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          regNumber: t('Неверный формат регистрационного номера'),
        },
      }));
    } else {
      this.setState((prevState) => ({
        regNumberValidation: {
          ...prevState.regNumberValidation,
          message: '',
          process: true,
        },
      }));

      registrationNumber( regNumber, (errors) => {
      //  console.log(regNumber);
          if (_.isNil(errors)) {        
            this.setState(() => ({          
              regNumberValidation: {
                successed: true,
                message: getDefaultTo(null, 'error.regNumber'),
                process: false,
              },                   
            }));   
            console.log(this.props.releaseFormList);
          }else{
            this.setState((prevState) => ({
              errors: { ...prevState.errors, regNumber: t('Не правильно указан регистрационный номер')},          
              regNumberValidation: {
                successed: false,
                message: getDefaultTo(
                  { error: { regNumber: t('Не правильно указан регистрационный номер')} },              
                  'error.regNumber',
                ),
                process: false,
              },
            }));      
          }
        });      

    }
  };

  isMarkGroupCategory = (value) => {
    const { t, markGroupCategory } = this.props;
    const data = {
      k3Id: value,
      markGroupId: 7,
    };  
    markGroupCategory(data, (isSuccessful) => {      
      if (isSuccessful) {       
        this.setState({ isMarkGroupCategory: true });
      } else {
        this.setState({ isMarkGroupCategory: false });
      }
    });
  };
  
  
  handleChange = (name, value, options) => {
    const { CatId } = this.state.data;
    const { modalType, releaseFormList } = this.props;
    this.banOnSaving(true);
    
    switch (name) {
      case 'gtin': {
        this.checkGtin(value);
        this.setState({
          listSubaccount: options,
        });
        break;
      }
      case 'tnvedId': {
        this.setState(
          (prevState) => ({ data: { ...prevState.data, [name]: value } }),
          this.setDefaultNeedClean,
        );
        break;
      }
      case 'CatId': {        
        this.props.clearReleaseFormList();
        this.setState((prevState) => ({
          data: { ...prevState.data, regNumber: '', releaseForm: '' },
          // releaseFormList: [],
        }));       
        if((value>=301809 && value<=301816) || value===303046){
          this.setState({ isModalOpen: true });
        }        
        if (modalType === 'technical') {
          if (value === '') {
            this.setState((prevState) => ({
              data: { ...prevState.data, [name]: value },
            }));
          } else this.checkCatId(value);
        } else
          this.setState((prevState) => ({
            data: { ...prevState.data, [name]: value },            
          }));     
          this.isMarkGroupCategory(value);     
        break;       
      }
      case 'currentCatName': {
        this.setState(() => ({
          [name]: value,
        }));
        break;
      }
      case 'releaseForm': {
        this.setState((prevState) => ({
          data: { ...prevState.data, [name]: value },
        }));
        break;
      }
      case 'optionId': {
        this.setState((prevState) => ({
          data: { ...prevState.data, [name]: value },
        }));
        if (CatId) {
          this.checkOptionId(value);
        }
        break;
      }
      case 'regNumber': {
        this.props.clearReleaseFormList();
        this.setState((prevState) => ({
          data: { ...prevState.data, releaseForm: '' },
          // releaseFormList: [],
        }));
        if (value === '') {
          this.setState((prevState) => ({
            data: { ...prevState.data, [name]: value },
          }));
        } else this.checkRegNumber(value);
        break;
      }

      default: {
        this.setState((prevState) => ({
          data: { ...prevState.data, [name]: value },
        }));
        break;
      }
    }
  };

  handleProducerSelectChange = (
    value,
    index = 0,
    rawValue,
    uniqueSubaccountList,
  ) => {
    let result;
    if (value && rawValue) {
      result = _.find(
        uniqueSubaccountList,
        (el) => el.uniqueId === rawValue.uniqueId,
      );
    }
    if (result) {
      const initItem = {
        id: _.uniqueId('producerId_'),
        inn: result.ITN,
        name: result.Name,
        gcp: result.GCP,
        direction: {
          address: result.Address,
          country: result.Country,
        },
        isActiveSelect: true,
        editable: false,
        accountId: result.AccountId,
      };
      const newInitItem = [...this.state.producerFields];
      newInitItem[index] = initItem;
      return this.setState({
        producerFields: newInitItem,
        currentProfile: true,
        producerChecked: true,
      });
    }

    switch (value) {
      case 'manually': {
        const manuallyInitItem = {
          ...initItem,
          id: _.uniqueId('producerId_'),
          isActiveSelect: true,
          manually: true,
          editable: true,
          accountId: null,
        };
        const newInitItem = [...this.state.producerFields];
        newInitItem[index] = manuallyInitItem;
        return this.setState({
          producerFields: newInitItem,
          currentProfile: false,
          producerChecked: true,
        });
      }
      case 'currentProfile': {
        const { company } = this.props.profile;
        const currentAccountList = [];
        /**
         * Городим новые костыли. надо сборать новый список из двух эндпоинтов.
         */
        let correspondAccountObject;

        /**
         * получили список объектов из profileStatus, в которых есть type: 0
         * т.е. аккаунт авторизованного пользователя у которого есть gcp
         */
        const profileObjectsWithGcp = _.filter(
          this.props.profileStatusData,
          (item) => {
            return item.type === 0;
          },
        );

        /**
         * в каждый объект который нашли выше вливаем данные из profile. Если не нашли, то пушим просто данные из company.
         */
        if (profileObjectsWithGcp.length) {
          _.forEach(profileObjectsWithGcp, (item) => {
            currentAccountList.push({ ...company, ...item });
          });
        } else {
          currentAccountList.push(company);
        }
        /**
         * т.к. в path, который приходит из опшенов, есть и gcp и inn, то смотрим, сколько частей через запятую нам пришло.
         * если три - то ищем схожий объект по gcp
         * если две - ищем схожий объект по inn
         */
        const serachingOption = rawValue.path
          .split(',')
          .slice(-1)
          .toString()
          .trim();
        const accountType =
          rawValue.path.split(',').length === 3 ? 'gcp' : 'inn';

        correspondAccountObject = _.find(currentAccountList, (account) => {
          return account[accountType] === serachingOption;
        });

        const initItem = {
          id: _.uniqueId('producerId_'),
          inn: correspondAccountObject.inn,
          name: correspondAccountObject.name,
          gcp: correspondAccountObject.gcp || '',
          direction: {
            address: correspondAccountObject.address,
            country: 'KZ',
          },
          isActiveSelect: true,
          editable: false,
          accountId: '',
        };
        const newInitItem = [...this.state.producerFields];
        newInitItem[index] = initItem;

        return this.setState({
          producerFields: newInitItem,
          currentOptionForProducer: value,
          currentProfile: true,
          producerChecked: true,
        });
      }
      case null: {
        const newInitItem = [...this.state.producerFields];
        newInitItem.splice(index, 1);

        const producerCheckedStatus =
          this.state.producerFields.length === 1 ? false : true;
        return this.setState({
          producerFields: newInitItem,
          producerChecked: producerCheckedStatus,
          currentProfile: false,
          currentOptionForProducer: null,
        });
      }
      default:
        return this.setState({
          producerFields: this.state.producerFields,
          currentOptionForProducer: value,
          currentProfile: false,
          producerChecked: true,
        });
    }
  };

  handleManufacturerImporterSelectChange = (
    value,
    type,
    rawValue,
    uniqueSubaccountList,
  ) => {
    let result;
    if (value && rawValue) {
      result = _.find(
        uniqueSubaccountList,
        (el) => el.uniqueId === rawValue.uniqueId,
      );
    }

    const isManufacturer = type === 'manufacturer';
    const fields = isManufacturer ? 'manufacturerFields' : 'importerFields';
    const currentOption = isManufacturer
      ? 'currentOptionForManufacturer'
      : 'currentOptionForImporter';
    const checked = isManufacturer ? 'manufacturerChecked' : 'importerChecked';
    const manufacturerId = isManufacturer ? 'manufacturerId_' : 'importerId_';

    if (result) {
      const initItem = {
        id: _.uniqueId(manufacturerId),
        inn: result.ITN,
        name: result.Name,
        gcp: result.GCP,
        direction: {
          address: result.Address,
          country: result.Country,
        },
        isActiveSelect: true,
        editable: false,
      };
      return this.setState({
        [fields]: [initItem],
        [currentOption]: value,
        currentProfile: true,
        [checked]: true,
      });
    }

    switch (value) {
      case 'manually': {
        return this.setState({
          [fields]: [
            {
              ...initItem,
              id: _.uniqueId(manufacturerId),
              isActiveSelect: true,
              manually: true,
              editable: true,
            },
          ],
          [currentOption]: value,
          currentProfile: false,
          [checked]: true,
        });
      }
      case 'currentProfile': {
        const { company } = this.props.profile;
        const currentAccountList = [];
        /**
         * Городим новые костыли. надо сборать новый список из двух эндпоинтов.
         */
        let correspondAccountObject;

        /**
         * получили список объектов из profileStatus, в которых есть type: 0
         * т.е. аккаунт авторизованного пользователя у которого есть gcp
         */
        const profileObjectsWithGcp = _.filter(
          this.props.profileStatusData,
          (item) => {
            return item.type === 0;
          },
        );

        /**
         * в каждый объект который нашли выше вливаем данные из profile. Если не нашли, то пушим просто данные из company.
         */
        if (profileObjectsWithGcp.length) {
          _.forEach(profileObjectsWithGcp, (item) => {
            currentAccountList.push({ ...company, ...item });
          });
        } else {
          currentAccountList.push(company);
        }
        /**
         * т.к. в path, который приходит из опшенов, есть и gcp и inn, то смотрим, сколько частей через запятую нам пришло.
         * если три - то ищем схожий объект по gcp
         * если две - ищем схожий объект по inn
         */
        const serachingOption = rawValue.path
          .split(',')
          .slice(-1)
          .toString()
          .trim();
        const accountType =
          rawValue.path.split(',').length === 3 ? 'gcp' : 'inn';

        correspondAccountObject = _.find(currentAccountList, (account) => {
          return account[accountType] === serachingOption;
        });

        const initItem = {
          id: _.uniqueId(manufacturerId),
          inn: correspondAccountObject.inn,
          name: correspondAccountObject.name,
          gcp: correspondAccountObject.gcp || '',
          direction: {
            address: correspondAccountObject.address,
            country: 'KZ',
          },
          isActiveSelect: true,
          editable: false,
        };

        return this.setState({
          [fields]: [initItem],
          [currentOption]: value,
          currentProfile: true,
          [checked]: true,
        });
      }
      case null: {
        return this.setState({
          [fields]: [{ ...initItem, id: _.uniqueId(manufacturerId) }],
          [currentOption]: '',
          currentProfile: false,
          [checked]: false,
        });
      }
      default:
        return this.setState({
          [fields]:
            type === 'manufacturer'
              ? this.state.manufacturerFields
              : this.state.importerFields,
          [currentOption]: value,
          currentProfile: false,
          [checked]: true,
        });
    }
  };

  /**
   * TODO:
   * Полностью переписать валидацию на что-то более осмысленно.
   */
  groupPackageFieldsFilled = () => {
    let groupRegisteredBlock = 0;
    let groupUnregisteredBlock = 0;

    let countSumRegistered = 0;
    let countSumUnregistered = 0;
    let undescribedCount = 0;

    _.forEach(this.state.groupPackageFields.arrayData, (obj) => {
      _.forEach(obj, (value, key) => {
        if (key !== 'id') {
          value
            ? subtractNotNegative(groupRegisteredBlock)
            : groupRegisteredBlock++;
          if (key === 'count') {
            countSumRegistered = countSumRegistered + Number(value);
          }
        }
      });
    });

    if (groupRegisteredBlock % 2 === 1) {
      return 'fieldError';
    }

    _.forEach(this.state.groupPackageFields.nestedUnregisteredData, (obj) => {
      _.forEach(obj, (value, key) => {
        if (key !== 'id') {
          value
            ? subtractNotNegative(groupUnregisteredBlock)
            : groupUnregisteredBlock++;
          if (key === 'count') {
            countSumUnregistered = countSumUnregistered + Number(value);
          }
        }
      });
    });

    if (groupUnregisteredBlock % 2 === 1) {
      return 'fieldError';
    }

    if (this.state.nestedUndescribedCount) {
      undescribedCount = Number(this.state.nestedUndescribedCount);
    }

    return countSumRegistered + countSumUnregistered + undescribedCount > 1;
  };

  validateGtinLength = () => {
    const { t, modalType, throwError } = this.props;
    const { gtin, optionId, CatId, count } = this.state.data;
    const { nestedArray } = this.state;
    const notConsumerPackage = _.includes(['transport'], modalType);
    /**
     * EACNC-261
     * Если вложенные зарегистрированные товары не мультиплицированы, то меняется логика проверки
     * самого gtin'а для транспортных(transport) и групповых(group) упаковок.
     */
    const isRegisteredMultipled = nestedArray.length > 1;

    if (_.isEmpty(gtin)) {
      this.setState((prevState) => ({
        errors: { ...prevState.errors, gtin: t('Неверный формат кода товара') },
      }));
    } else if (
      notConsumerPackage &&
      !packageGtinValidLength(gtin, isRegisteredMultipled)
    ) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          gtin: t('Укажите корректный код упаковки'),
        },
      }));
    } else if (!notConsumerPackage && !gtinValidLength(gtin)) {
      this.setState((prevState) => ({
        errors: { ...prevState.errors, gtin: t('Неверный формат кода товара') },
      }));
    } else {
      this.setState((prevState) => ({
        gtinValidation: {
          ...prevState.gtinValidation,
          message: '',
          process: true,
        },
      }));

      // Проверка для групповой упаковки
      if (modalType === 'group') {
        if (this.groupPackageFieldsFilled() === false) {
          throwError(
            t(
              'Для регистрации групповой упаковки количество вложенных упаковок должно быть больше одной.',
            ),
          );
          return this.setState((prevState) => ({
            errors: {
              ...prevState.errors,
              gtin: t(
                'Для регистрации групповой упаковки количество вложенных упаковок должно быть больше одной.',
              ),
            },
          }));
        } else if (this.groupPackageFieldsFilled() === 'fieldError') {
          throwError(t('Не все поля заполнены'));
          return this.setState((prevState) => ({
            errors: {
              ...prevState.errors,
              gtin: t('Не все поля были заполнены.'),
            },
          }));
        } else {
          const { groupPackageFields } = this.state;

          const groupData = _.cloneDeep(groupPackageFields);
          const filteredGroupData = _.forEach(groupData, (outerObject) => {
            _.forEach(outerObject, (innerObject) => {
              delete innerObject.id;
            });
          });

          const data = {
            arrayData: filteredGroupData.arrayData,
            nestedUnregisteredData: filteredGroupData.nestedUnregisteredData,
            gtin: gtin,
            type: modalType,
            generateCode: false, // на форме сейчас отсутствует возможность сгенерить код, true должно быть если код снегенири, а не ввели руками.
          };

          this.props.validateGroupAndTransportGtin(
            data,
            (errors, successed, response) => {
              const goodDraftId = getDefaultTo(response, 'goodDraftId', null);
              const isCopyGood = getDefaultTo(response, 'isCopyGood', false);
              const status = getDefaultTo(response, 'status', null);
              const createExemplar = getDefaultTo(response, 'continue', false);

              if (createExemplar) {
                this.setState({
                  showCopyAlert: true,
                });
              }

              if (!_.isNil(goodDraftId)) {
                const fieldDisabledAll = _.mapValues(
                  this.state.fieldDisabled,
                  () => true,
                );

                if (isCopyGood) {
                  // Создания экземпляра карточки импортного товара
                  throwError(`
                                    ${t(
                                      'Карточка с таким Кодом товара уже зарегистрирована в Каталоге',
                                    )}. 
                                    ${t(
                                      'Заполните полное наименование товара и перейдите в полную форму карточки, ' +
                                        'нажав кнопку «Подтвердить»',
                                    )}
                                `);
                  this.setState({ fieldDisabled: { ...fieldDisabledAll } });
                } else {
                  const text = `${t(
                    'Внимание! Код товара зарегистрирован в Каталоге',
                  )}. 
                                    ${t(
                                      'Вы можете продолжить внесение данных в открытом окне или, нажав «Подтвердить», ' +
                                        'в случае, если все поля окна заполнены',
                                    )}`;

                  if (status === 'draft') {
                    throwError(text);
                    this.setState({ fieldDisabled: fieldDisabledAll });
                  }

                  if (status === 'require_changes') {
                    throwError(text);
                  }
                }

                this.loadModal(goodDraftId);
              }

              this.setState({
                gtinValidation: {
                  successed: successed,
                  message: getDefaultTo(errors, 'error.gtin'),
                  process: false,
                },
                goodDraftId,
                isCopyGood,
                status,
              });
            },
          );

          return;
        }
      }

      // Отдельная проврека для транспортной упаковки
      if (modalType === 'transport') {
        const { nestedArray } = this.state;
        const groupAndTransportData = _.cloneDeep(nestedArray);
        const filteredNestedArray = _.forEach(
          groupAndTransportData,
          (arrayItem) => {
            delete arrayItem.id;
          },
        );

        const data = {
          arrayData: filteredNestedArray,
          gtin: gtin,
          type: modalType,
          generateCode: false, // на форме сейчас отсутствует возможность сгенерить код, true должно быть если код снегенири, а не ввели руками.
        };

        this.props.validateGroupAndTransportGtin(
          data,
          (errors, successed, response) => {
            const goodDraftId = getDefaultTo(response, 'goodDraftId', null);
            const isCopyGood = getDefaultTo(response, 'isCopyGood', false);
            const status = getDefaultTo(response, 'status', null);
            const createExemplar = getDefaultTo(response, 'continue', false);

            if (createExemplar) {
              return this.setState({
                showCopyAlert: true,
              });
            }

            if (!_.isNil(goodDraftId)) {
              const fieldDisabledAll = _.mapValues(
                this.state.fieldDisabled,
                () => true,
              );

              if (isCopyGood) {
                // Создания экземпляра карточки импортного товара
                throwError(`
                                ${t(
                                  'Карточка с таким Кодом товара уже зарегистрирована в Каталоге',
                                )}. 
                                ${t(
                                  'Заполните полное наименование товара и перейдите в полную форму карточки, ' +
                                    'нажав кнопку «Подтвердить»',
                                )}
                            `);
                this.setState({ fieldDisabled: { ...fieldDisabledAll } });
              } else {
                const text = `${t(
                  'Внимание! Код товара зарегистрирован в Каталоге',
                )}. 
                                ${t(
                                  'Вы можете продолжить внесение данных в открытом окне или, нажав «Подтвердить», ' +
                                    'в случае, если все поля окна заполнены',
                                )}`;

                if (status === 'draft') {
                  throwError(text);
                  this.setState({ fieldDisabled: fieldDisabledAll });
                }

                if (status === 'require_changes') {
                  throwError(text);
                }
              }

              this.loadModal(goodDraftId);
            }

            this.setState({
              gtinValidation: {
                successed: successed,
                message: getDefaultTo(errors, 'error.gtin'),
                process: false,
              },
              goodDraftId,
              isCopyGood,
              status,
            });
          },
        );
        return;
      }

      // Отдельная проврека для технической карточки
      if (modalType === 'technical') {
        const { nestedArray } = this.state;
        const groupAndTransportData = _.cloneDeep(nestedArray);
        const filteredNestedArray = _.forEach(
          groupAndTransportData,
          (arrayItem) => {
            delete arrayItem.id;
          },
        );

        const data = {
          optionId: optionId,
          CatId: CatId,
          //arrayData: filteredNestedArray,
          gtin: gtin,
          count: count,
          //type: modalType,
          //generateCode: false, // на форме сейчас отсутствует возможность сгенерить код, true должно быть если код снегенири, а не ввели руками.
        };

        this.props.validateTechnicalCard(
          data,
          (errors, successed, response) => {
            const goodDraftId = getDefaultTo(response, 'goodDraftId', null);
            const isCopyGood = getDefaultTo(response, 'isCopyGood', false);
            const status = getDefaultTo(response, 'status', null);
            const createExemplar = getDefaultTo(response, 'continue', false);

            if (createExemplar) {
              return this.setState({
                showCopyAlert: true,
              });
            }

            if (!_.isNil(goodDraftId)) {
              const fieldDisabledAll = _.mapValues(
                this.state.fieldDisabled,
                () => true,
              );

              if (isCopyGood) {
                // Создания экземпляра карточки импортного товара
                throwError(`
                                      ${t(
                                        'Карточка с таким Кодом товара уже зарегистрирована в Каталоге',
                                      )}. 
                                      ${t(
                                        'Заполните полное наименование товара и перейдите в полную форму карточки, ' +
                                          'нажав кнопку «Подтвердить»',
                                      )}
                                  `);
                this.setState({ fieldDisabled: { ...fieldDisabledAll } });
              } else {
                const text = `${t(
                  'Внимание! Код товара зарегистрирован в Каталоге',
                )}. 
                                      ${t(
                                        'Вы можете продолжить внесение данных в открытом окне или, нажав «Подтвердить», ' +
                                          'в случае, если все поля окна заполнены',
                                      )}`;

                if (status === 'draft') {
                  throwError(text);
                  this.setState({ fieldDisabled: fieldDisabledAll });
                }

                if (status === 'require_changes') {
                  throwError(text);
                }
              }

              this.loadModal(goodDraftId);
            }

            this.setState({
              gtinValidation: {
                successed: successed,
                message: getDefaultTo(errors, 'error.gtin'),
                process: false,
              },
              goodDraftId,
              isCopyGood,
              status,
            });
          },
        );
        return;
      }

      // Проверка для потребительской упаковки
      this.props.validateGtin(
        this.getReguestData(),
        (errors, successed, response) => {
          const goodDraftId = getDefaultTo(response, 'goodDraftId', null);
          const isCopyGood = getDefaultTo(response, 'isCopyGood', false);
          const status = getDefaultTo(response, 'status', null);
          const createExemplar = getDefaultTo(response, 'continue', false);

          if (createExemplar) {
            return this.setState({
              gtinValidation: {
                successed: successed,
                message: getDefaultTo(errors, 'error.gtin'),
                process: false,
              },
              showCopyAlert: true,
            });
          }

          if (!_.isNil(goodDraftId)) {
            const fieldDisabledAll = _.mapValues(
              this.state.fieldDisabled,
              () => true,
            );

            if (isCopyGood) {
              throwError(`
                            ${t(
                              'Карточка с таким Кодом товара уже зарегистрирована в Каталоге',
                            )}. 
                            ${t(
                              'Заполните полное наименование товара и перейдите в полную форму карточки, ' +
                                'нажав кнопку «Подтвердить»',
                            )}
                        `);
              this.setState({ fieldDisabled: { ...fieldDisabledAll } });
            } else {
              const text = `${t(
                'Внимание! Код товара зарегистрирован в Каталоге',
              )}. 
                            ${t(
                              'Вы можете продолжить внесение данных в открытом окне или, нажав «Подтвердить», ' +
                                'в случае, если все поля окна заполнены',
                            )}`;

              if (status === 'draft') {
                throwError(text);
                this.setState({ fieldDisabled: fieldDisabledAll });
              }

              if (status === 'require_changes') {
                throwError(text);
              }
            }

            this.loadModal(goodDraftId);
          }

          this.setState({
            gtinValidation: {
              successed: successed,
              message: getDefaultTo(errors, 'error.gtin'),
              process: false,
            },
            goodDraftId,
            isCopyGood,
            status,
          });
        },
      );
    }
  };

  // checkGtin = (value) => {
  //   const v = value.replace(/[^[0-9]/, '');
  //   this.setState(
  //     (prevState) => ({
  //       data: { ...prevState.data, gtin: v },
  //       errors: { ...prevState.errors, gtin: false },
  //       gtinValidation: { ...prevState.gtinValidation, message: '' },
  //     }),
  //     this.validateGtinLengthDebounce,
  //   );
  // };

  checkGtin = (value) => {
    const v = value.replace(/[^[0-9]/, '');
    this.setState(
      (prevState) => ({
        data: { ...prevState.data, gtin: v },
        errors: { ...prevState.errors, gtin: false },
        gtinValidation: { message: '', successed: false, process: false },
      }),
      this.validateGtinLengthDebounce,
    );
  };

  modalNewGtin = () =>
    this.setState({ openModalNewGtin: !this.state.openModalNewGtin });

  returnGtin = (gtin) => {
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          gtin: gtin,
        },
        gtinValidation: {
          process: false,
          message: '',
          successed: true,
        },
        errors: { ...prevState.errors, gtin: false },
      }),
      this.modalNewGtin,
    );
  };

  modalGcp = () => this.setState({ openGcpModal: !this.state.openGcpModal });

  createNewConsumerCard = (e = null) => {
    const { t } = this.props;
    if (e) {
      e.preventDefault();
    }
    const { modalType } = this.props;

    // тут начинается создание group package
    if (modalType === 'group') {
      const { groupPackageFields, nestedUndescribedCount } = this.state;
      const { gtin, CatId } = this.state.data;

      const groupData = _.cloneDeep(groupPackageFields);
      const filteredGroupData = _.forEach(groupData, (outerObject, index) => {
        _.forEach(outerObject, (innerObject, index) => {
          if (innerObject && innerObject.id) {
            delete innerObject.id;
          }
          if (innerObject && !innerObject.nestedPackagingCode) {
            outerObject.splice(index, 1);
          }
        });
        if (groupData[index] && _.isEmpty(groupData[index])) {
          delete groupData[index];
        }
      });

      let data = {
        arrayData: !_.isEmpty(filteredGroupData.arrayData)
          ? filteredGroupData.arrayData
          : [],
        nestedUnregisteredData: !_.isEmpty(
          filteredGroupData.nestedUnregisteredData,
        )
          ? filteredGroupData.nestedUnregisteredData
          : [],
        nestedUndescribedCount: Number(nestedUndescribedCount),
        CatId,
        gtin,
        packageType: modalType,
        generateCode: false,
      };

      this.props.createConsumerPackaging(data);
      return;
    }
    // тут закончилось создание group package

    // тут начинается создание technical card
    if (modalType === 'technical') {
      const { technicalPackagingFields, nestedUndescribedCount } = this.state;
      const { gtin, CatId, optionId, count } = this.state.data;

      const technicalData = _.cloneDeep(technicalPackagingFields);
      const filteredTechnicalData = _.forEach(
        technicalData,
        (outerObject, index) => {
          _.forEach(outerObject, (innerObject, index) => {
            if (innerObject && innerObject.id) {
              delete innerObject.id;
            }
            if (innerObject && !innerObject.nestedPackagingCode) {
              outerObject.splice(index, 1);
            }
          });
          if (technicalData[index] && _.isEmpty(technicalData[index])) {
            delete technicalData[index];
          }
        },
      );

      let data = {
        // arrayData: !_.isEmpty(filteredTechnicalData.arrayData)
        //   ? filteredTechnicalData.arrayData
        //   : [],
        // nestedUnregisteredData: !_.isEmpty(
        //   filteredTechnicalData.nestedUnregisteredData,
        // )
        //   ? filteredTechnicalData.nestedUnregisteredData
        //   : [],
        // nestedUndescribedCount: Number(nestedUndescribedCount),
        optionId,
        CatId,
        count,
        gtin,
        //packageType: modalType,
        //generateCode: false,
      };

      this.props.createTechnicalPackaging(data);
      return;
    }
    // тут закончилось создание technical card

    // тут начинается создание transport package
    if (modalType === 'transport') {
      const { nestedArray } = this.state;
      const { gtin } = this.state.data;
      const groupAndTransportData = _.cloneDeep(nestedArray);
      const filteredNestedArray = _.forEach(
        groupAndTransportData,
        (arrayItem) => {
          delete arrayItem.id;
        },
      );
      const data = {
        arrayData: filteredNestedArray,
        gtin,
        packageType: modalType,
        generateCode: false,
      };
      this.props.createConsumerPackaging(data);
      return;
    }
    // закончилась ветка transport pagacke, дальше создание consumer.

    this.setState({ buttonSaveDisable: true });

    const requestFormData = this.getReguestData('create');
    this.props.createConsumerPackaging(requestFormData);
  };

  switchModalType = () => {
    const { modalType } = this.props;
    const { data, gtinValidation, catIdValidation } = this.state;
    if (modalType === 'unit' || modalType === 'group') {
      return !data.CatId;
    }
          //  
    if (modalType === 'technical') {
      if (data.gtin) return !gtinValidation.successed || !data.optionId; 
      else return (
          !data.CatId ||
          !data.count ||
          !data.optionId ||
          data.count > 100 ||
          data.count < 1 ||
          !catIdValidation.successed
        );
    } else return !this.isReadySubmitGroupTransport();
  };

  getReguestData = (type) => {
    const {
      manufacturerFields,
      producerFields,
      importerFields,
      data,
    } = this.state;

    const requestData = {
      gtin: data.gtin,
      subaccountType0: [],
      subaccountType1: [],
      subaccountType2: [],
    };

    if (type && type === 'create') {
      requestData.packageType = 'consumer';
      requestData.CatId = data.CatId;
      requestData.regNumber = data.regNumber
      requestData.releaseForm = data.releaseForm
    }

    if (type && type === 'createExemplar') {
      requestData.packageType = 'consumer';
      requestData.CatId = '';
    }

    _.forEach(
      [manufacturerFields, producerFields, importerFields],
      (typeFields) => {
        _.forEach(typeFields, (consumerSource) => {
          const requestObj = { ...consumerSource };
          requestObj.address = requestObj.direction.address;
          requestObj.country = requestObj.direction.country;
          requestObj.GCP = requestObj.gcp;
          requestObj.nameCompany = requestObj.name;

          const newRequestObj = _.omit(requestObj, [
            'direction',
            'gcp',
            'name',
            'id',
            'isActiveSelect',
            'typeId',
            'manually',
            'editable',
          ]);

          if (consumerSource.isActiveSelect) {
            if (consumerSource.id.indexOf('manufacturerId_') === 0)
              requestData.subaccountType0.push(newRequestObj);
            if (consumerSource.id.indexOf('producerId_') === 0)
              requestData.subaccountType1.push(newRequestObj);
            if (consumerSource.id.indexOf('importerId_') === 0)
              requestData.subaccountType2.push(newRequestObj);
          }
        });
      },
    );

    return requestData;
  };

  handleClose = (onlyClose) => this.props.handleClose(onlyClose);

  titleModal = () => {
    const { t, modalType } = this.props;
    switch (modalType) {
      case 'kit':
        return t('Описание комплекта');
      case 'technical':
        return t('Регистрация технической карточки');
      case 'technicalUnmarked':
        return t('Регистрация немаркируемого товара (не имеющего GTIN)');
      case 'group':
        return t('Регистрация групповой упаковки');
      case 'transport':
        return t('Регистрация транспортной упаковки');
      default:
        return t('Регистрация потребительской упаковки');
    }
  };

  sendSubaccount = (data) => {
    const subAccountData = _.cloneDeep(data);

    if (subAccountData.direction) {
      subAccountData.address = subAccountData.direction.address;
      subAccountData.country = subAccountData.direction.country;
      delete subAccountData.direction;
    }

    this.props.sendSubAccount(subAccountData);
  };

  updateItem = (id, fieldData, errors, type) => {
    if (
      _.has(fieldData, 'nestedPackagingCode') &&
      !fieldData.nestedPackagingCode
    ) {
      fieldData.count = '';
    }
    if (type === 'registered') {
      const groupPackageFields = _.cloneDeep(this.state.groupPackageFields);
      _.forEach(groupPackageFields.arrayData, (v, k) => {
        if (v.id === id) {
          groupPackageFields.arrayData[k] = _.assign({}, v, fieldData);
        }
      });
      if (
        _.has(fieldData, 'count') &&
        !_.has(fieldData, 'nestedPackagingCode')
      ) {
        this.setState((prevState) => ({
          groupPackageFields,
          errors: { ...prevState.errors, registereCountArrayErrros: errors },
        }));
        return;
      }
      this.setState((prevState) => ({
        groupPackageFields,
        errors: { ...prevState.errors, registereArrayErrros: errors },
      }));
    } else if (type === 'unregistered') {
      const groupPackageFields = _.cloneDeep(this.state.groupPackageFields);
      _.forEach(groupPackageFields.nestedUnregisteredData, (v, k) => {
        if (v.id === id) {
          groupPackageFields.nestedUnregisteredData[k] = _.assign(
            {},
            v,
            fieldData,
          );
        }
      });
      if (
        _.has(fieldData, 'count') &&
        !_.has(fieldData, 'nestedPackagingCode')
      ) {
        this.setState((prevState) => ({
          groupPackageFields,
          errors: { ...prevState.errors, unregistereCountdArrayErrros: errors },
        }));
        return;
      }
      this.setState((prevState) => ({
        groupPackageFields,
        errors: { ...prevState.errors, unregisteredArrayErrros: errors },
      }));
    } else if (type === 'nocode') {
      const { nocode_count } = fieldData;
      this.setState({
        nestedUndescribedCount: nocode_count,
      });
    } else {
      const nestedArray = _.cloneDeep(this.state.nestedArray);
      _.forEach(nestedArray, (v, k) => {
        if (v.id === id) {
          nestedArray[k] = _.assign({}, v, fieldData);
        }
      });
      this.setState((prevState) => ({
        nestedArray,
        errors: { ...prevState.errors, nestedArrayErrros: errors },
      }));
    }
  };

  addItem = (type) => {
    switch (type) {
      case 'manufacturer':
        const manufacturerFields = this.state.manufacturerFields;
        manufacturerFields.push({
          ...initItem,
          id: 'manufacturerId_',
          isActiveSelect: true,
          editable: true,
        });
        return this.setState({ manufacturerFields });
      case 'producer':
        const producerFields = this.state.producerFields;
        producerFields.push({
          ...initItem,
          id: _.uniqueId('producerId_'),
          isActiveSelect: true,
          editable: true,
        });
        return this.setState({ producerFields });
      case 'importer':
        const importerFields = this.state.importerFields;
        importerFields.push({
          ...initItem,
          id: _.uniqueId('importerId_'),
          isActiveSelect: true,
          editable: true,
        });
        return this.setState({ importerFields });
      case 'registered':
        let groupPackageRegisteredFields = _.cloneDeep(
          this.state.groupPackageFields,
        );
        groupPackageRegisteredFields.arrayData.push(
          _.assign(initFields, { id: _.uniqueId('nestedArray_') }),
        );
        return this.setState({
          groupPackageFields: groupPackageRegisteredFields,
          data: { gtin: '' },
          gtinValidation: { process: false, message: '', successed: false },
        });
      case 'unregistered':
        const groupPackageUnregisteredFields = _.cloneDeep(
          this.state.groupPackageFields,
        );
        groupPackageUnregisteredFields.nestedUnregisteredData.push(
          _.assign(initFields, { id: _.uniqueId('nestedArray_') }),
        );
        return this.setState({
          groupPackageFields: groupPackageUnregisteredFields,
          data: { gtin: '' },
          gtinValidation: { process: false, message: '', successed: false },
        });
      default:
        const nestedArray = _.cloneDeep(this.state.nestedArray);
        nestedArray.push(
          _.assign(initFields, { id: _.uniqueId('nestedArray_') }),
        );
        return this.setState({
          nestedArray,
          data: { gtin: '' },
          gtinValidation: { process: false, message: '', successed: false },
        });
    }
  };

  deleteItem = (id, type) => {
    switch (type) {
      case 'producer':
        const producerFields = this.state.producerFields.filter(
          (el, index) => index !== id,
        );
        return this.setState({ producerFields });
      case 'importer':
        const importerFields = this.state.importerFields.filter(
          (el, index) => index !== id,
        );
        return this.setState({ importerFields });
      case 'transportAndGroup':
        const nestedArray = this.state.nestedArray.filter(
          (item, index) => item.id !== id,
        );
        return this.setState({
          nestedArray,
          data: { gtin: '' },
          gtinValidation: { process: false, message: '', successed: false },
        });
      case 'registered':
        const groupRegisteredPackageFields = _.cloneDeep(
          this.state.groupPackageFields,
        );
        groupRegisteredPackageFields.arrayData = groupRegisteredPackageFields.arrayData.filter(
          (item, index) => item.id !== id,
        );
        return this.setState((prevState) => ({
          groupPackageFields: groupRegisteredPackageFields,
          data: { gtin: '' },
          gtinValidation: { process: false, message: '', successed: false },
          errors: {
            ...prevState.errors,
            registereArrayErrros: false,
            registereCountArrayErrros: false,
          },
        }));
      case 'unregistered':
        const groupUnregisteredPackageFields = _.cloneDeep(
          this.state.groupPackageFields,
        );
        groupUnregisteredPackageFields.nestedUnregisteredData = groupUnregisteredPackageFields.nestedUnregisteredData.filter(
          (item, index) => item.id !== id,
        );
        return this.setState((prevState) => ({
          groupPackageFields: groupUnregisteredPackageFields,
          data: { gtin: '' },
          gtinValidation: { process: false, message: '', successed: false },
          errors: {
            ...prevState.errors,
            unregisteredArrayErrros: false,
            unregistereCountdArrayErrros: false,
          },
        }));
      default:
        return;
    }
  };

  updateUnregCount = (e) => {
    e = _.toString(e).replace(/[^[0-9]/, '');
    e = e === '0' ? '' : e;

    this.setState({
      nestedUndescribedCount: e,
    });
  };

  confirmAlertModal = () => {
    this.setState({
      showCopyAlert: false,
    });

    return this.createNewConsumerCard();
  };

  cancelAlertModal = () => {
    return this.setState({
      showCopyAlert: false,
    });
  };

  gcpIsValid = (gpc) => {
    return _.includes([4, 5, 6, 7, 8, 9], _.size(_.toString(gpc)));
  };

  isReadySubmitGroup = () => {
    const { errors, gtinValidation, data } = this.state;
    if (this.props.generatedGtin) {
      return gtinValidation.successed && !errors.nestedArrayErrros;
    } else {
      const gtinValid = gtinValidation.successed && data.gtin;
      return gtinValid && !errors.nestedArrayErrros;
    }
  };

  groupAndTrasportFiledsFilled = () => {
    let count = 0;
    const { nestedArray } = this.state;

    _.forEach(nestedArray, (obj) => {
      _.forEach(Object.values(obj), (value) => {
        return value ? subtractNotNegative(count) : count++;
      });
    });

    return count === 0;
  };

  groupFieldsFilled = () => {
    let count = 0;
    const { groupPackageFields } = this.state;

    const array =
      groupPackageFields.nestedUnregisteredData[0].nestedPackagingCode !== ''
        ? groupPackageFields.arrayData.concat(
            groupPackageFields.nestedUnregisteredData,
          )
        : groupPackageFields.arrayData;

    _.forEach(array, (obj) => {
      _.forEach(Object.values(obj), (value) => {
        return value ? subtractNotNegative(count) : count++;
      });
    });

    return count === 0;
  };

  isReadySubmitGroupTransport = () => {
    const { errors, gtinValidation, data } = this.state;
    if (this.props.generatedGtin) {
      return (
        gtinValidation.successed &&
        !errors.nestedArrayErrros &&
        this.groupAndTrasportFiledsFilled()
      );
    } else {
      const gtinValid = gtinValidation.successed && data.gtin;

      return (
        gtinValid &&
        !errors.nestedArrayErrros &&
        this.groupAndTrasportFiledsFilled()
      );
    }
  };

  innIsValid = (inn) => {
    return inn.length >= 3 && inn.length < 15;
  };

  fieldsFilled = () => {
    const { manufacturerFields, producerFields, importerFields } = this.state;
    let isRequiredManufacturerValid = true;
    let isRequiredProducerValidAll = true;
    let isRequiredProducerValid = true;
    let isRequiredValid = true;
    let isOneOfTypesSelected = false;

    _.forEach([manufacturerFields, producerFields, importerFields], (el, i) => {
      if (Array.isArray(el) && el.length === 0 && i === 1) {
        isRequiredProducerValid = false;
        return;
      }
      const obj = el[0];
      if ([0, 1, 2].includes(i)) {
        if (obj.isActiveSelect) {
          isOneOfTypesSelected = true;
          i === 0
            ? (isRequiredManufacturerValid = !(
                !obj.inn ||
                !this.innIsValid(obj.inn) ||
                !obj.name ||
                !obj.gcp ||
                !this.gcpIsValid(obj.gcp) ||
                !obj.direction.country ||
                !obj.direction.address
              ))
            : i === 1
            ? (isRequiredProducerValid = !(
                !obj.inn ||
                !this.innIsValid(obj.inn) ||
                !obj.name ||
                !obj.direction.country ||
                !obj.direction.address ||
                (obj.gcp && !this.gcpIsValid(obj.gcp))
              ))
            : i === 3
            ? (isRequiredValid =
                !!obj.inn ||
                !obj.name ||
                !obj.direction.country ||
                !obj.direction.address ||
                (obj.gcp && !this.gcpIsValid(obj.gcp)))
            : void 0;
        } else {
          i === 0
            ? (isRequiredManufacturerValid = false)
            : i === 1
            ? (isRequiredProducerValid = false)
            : i === 3
            ? (isRequiredValid = false)
            : void 0;
        }
      }
      if (producerFields.length > 1) {
        _.forEach(producerFields, (a) => {
          if (
            !a.inn ||
            !this.innIsValid(a.inn) ||
            !a.name ||
            !a.direction.country ||
            !a.direction.address ||
            (a.gcp && !this.gcpIsValid(a.gcp))
          )
            isRequiredProducerValidAll = false;
        });
      }
    });
    if (importerFields[0].isActiveSelect) {
      const a = importerFields[0];
      if (
        !a.inn ||
        !this.innIsValid(a.inn) ||
        !a.name ||
        !a.direction.country ||
        !a.direction.address ||
        (a.gcp && !this.gcpIsValid(a.gcp))
      ) {
        isRequiredValid = false;
      }
    }
    return (
      isRequiredManufacturerValid &&
      isRequiredProducerValid &&
      isRequiredValid &&
      isOneOfTypesSelected &&
      isRequiredProducerValidAll
    );
  };

  checkAvailabilityCreationGtin = (
    packingPurpose,
    gcpPrefix,
    gtinList = '',
    gtinType = '',
  ) => {
    const body = {
      packingPurpose,
    };
    if (packingPurpose === 'consumer') {
      body.gcpPrefix = gcpPrefix;
    }
    if (gtinList) {
      body.gtinList = gtinList;
    }
    if (gtinType) {
      body.gtinType = gtinType;
    }
    this.props.checkAvailabilityCreationGtin(body, (error) => {
      if (error) {
        this.setState({ btnGtinTooltipTitle: error });
      } else this.setState({ btnGtinTooltipTitle: '' });
    });
  };

  renderGroupPackageFields = (arr, modalType) => {
    const {
      gtinsListByPackageType,
      productId,
      gtinsListForValidation,
    } = this.props;
    const {
      gtinValidation,
      goodDraftId,
      nestedArray,
      groupPackageFields,
    } = this.state;
    const groupAndTransportButtonsActive = _.every(_.values(nestedArray[0]));
    const groupPackageRegisteredAdd = _.every(
      _.values(groupPackageFields.arrayData[0]),
    );
    const groupPackageUnregisteredAdd = _.every(
      _.values(groupPackageFields.nestedUnregisteredData[0]),
    );
    const groupTransportDisabledGTIN =
      !_.isNaN(productId) || gtinValidation.successed || !_.isNil(goodDraftId);

    const array = Array.isArray(arr) ? arr : groupPackageFields[arr];

    return _.map(array, (value, index) => {
      const defaultProps = {
        data: value,
        key: value.id,
        index,
        updateItem: this.updateItem,
        selectOptions: gtinsListByPackageType,
        addItem: this.addItem,
        deleteItem: this.deleteItem,
      };

      if (arr === 'arrayData') {
        return (
          <MultipleAndTransportItem
            {...defaultProps}
            selectedOptions={groupPackageFields.arrayData}
            groupAndTransportButtonsDisabled={!groupAndTransportButtonsActive}
            groupRegisteredAddDisabled={!groupPackageRegisteredAdd}
            lockAllFields={groupTransportDisabledGTIN}
            type="groupReg"
          />
        );
      }

      if (arr === 'nestedUnregisteredData') {
        return (
          <MultipleAndTransportItem
            {...defaultProps}
            selectedOptions={groupPackageFields.nestedUnregisteredData}
            groupAndTransportButtonsDisabled={!groupAndTransportButtonsActive}
            groupUnregisteredAddDisabled={!groupPackageUnregisteredAdd}
            lockAllFields={groupTransportDisabledGTIN}
            validationList={gtinsListForValidation}
            type="groupUnreg"
            validateUnregistredGtin={(data, cbk) =>
              this.props.validateUnregistredGtin(data, cbk)
            }
            throwError={this.props.throwError}
          />
        );
      }

      return (
        <MultipleAndTransportItem
          {...defaultProps}
          selectedOptions={nestedArray}
          groupAndTransportButtonsDisabled={!groupAndTransportButtonsActive}
          lockAllFields={groupTransportDisabledGTIN}
          type={modalType}
        />
      );
    });
  };

  renderModalInfo = (requiredFields) => {
    const { t } = this.props;
    const { editingIsPermitted } = this.state;

    return requiredFields ? (
      <ModalInfoRequiredValid>
        <span className="icon">
          <IconValidMin />
        </span>
        {t('Все обязательные поля заполнены')}
      </ModalInfoRequiredValid>
    ) : (
      <ModalInfoRequired className={cx({ disabled: editingIsPermitted })}>
        <span className="icon">
          <IconNewProductError />
        </span>
        <div
          dangerouslySetInnerHTML={{
            __html: t('Красным отмечены обязательные для заполнения поля'),
          }}
        />
      </ModalInfoRequired>
    );
  };

  renderDialogGroupContent = (
    groupTransportDisabledGTIN,
    atleastOneFieldIsFilled,
  ) => {
    const {
      t,
      activeKzDictionary,
      isAccountCanCreateGtin,
      generatedGtin,
      isAvailabilityCreationGtin,
      releaseFormList,
    } = this.props;
    const {
      editingIsPermitted,
      gtinValidation,
      errors,
      data,
      nestedUndescribedCount,
      groupPackageFields,
    } = this.state;
    const { gtin } = data;

    const disabled =
      errors.registereArrayErrros ||
      errors.unregisteredArrayErrros ||
      errors.registereCountArrayErrros ||
      errors.unregistereCountArrayErrros ||
      !atleastOneFieldIsFilled ||
      groupTransportDisabledGTIN;

    const label = !gtin
      ? t('Введите код упаковки')
      : !_.isEmpty(gtinValidation.message)
      ? t('Введите корректный код упаковки')
      : t('Код упаковки');

    const errorsRender = errors.gtin ? (
      SimpleTooltip(errors.gtin, <IconError />)
    ) : !_.isEmpty(gtinValidation.message) ? (
      SimpleTooltip(gtinValidation.message, <IconError />)
    ) : gtinValidation.process ? (
      <InputIcon>
        <IconPreloader />
      </InputIcon>
    ) : gtinValidation.successed ? (
      <InputIcon>
        <IconValid />
      </InputIcon>
    ) : (
      SimpleTooltip(t('Код товара состоит из 13 цифр'), <IconInfoTooltip />)
    );

    return (
      <DialogContent>
        <DialogTitleOfContentStyled>
          {' '}
          {t('Вложенные зарегистрированные товары (Опубликованные)')}{' '}
        </DialogTitleOfContentStyled>
        {this.renderGroupPackageFields('arrayData')}

        <DialogTitleOfContentStyledWithMargin>
          {' '}
          {t('Вложенные незарегистрированные товары')}{' '}
        </DialogTitleOfContentStyledWithMargin>
        {this.renderGroupPackageFields('nestedUnregisteredData')}

        {/**
         *  Блок для "Вложенные товары без кода"
         */}
        <MuiThemeProvider theme={defaultTheme}>
          <DialogTitleOfContentStyledWithMargin>
            {' '}
            {t('Вложенные товары без кода')}{' '}
          </DialogTitleOfContentStyledWithMargin>
          <CustomInput
            name="nocode_count"
            type="number"
            valid={!!nestedUndescribedCount}
            dirty={true}
            label={t('Укажите количество вложенных упаковок без кода')}
            onChange={this.updateUnregCount}
            value={nestedUndescribedCount || ''}
            disabled={groupTransportDisabledGTIN}
          />
        </MuiThemeProvider>

        {/**
         * TODO:
         * Переписать disabled на функцию.
         */}
        <ProductBlockNew>
          <ProductBlockNewLeft>
            <MuiThemeProvider theme={defaultTheme}>
              <InputContainer>
                <CustomInput
                  id="transportAndGroupGtin"
                  name="gtin"
                  type="text"
                  maxLength={13}
                  required={true}
                  disabled={disabled}
                  label={label}
                  onUpdate={() => {}}
                  onChange={(value) => this.handleChange('gtin', value)}
                  value={
                    groupTransportDisabledGTIN || !editingIsPermitted
                      ? packageGtinDisplay(_.defaultTo(gtin, ''))
                      : _.defaultTo(gtin, '')
                  }
                  error={!!errors.gtin || !_.isEmpty(gtinValidation.message)}
                  valid={gtinValidation.successed}
                />
                {errorsRender}
              </InputContainer>
            </MuiThemeProvider>
          </ProductBlockNewLeft>
          {isAccountCanCreateGtin && (
            <ProductBlockNewRight style={{ width: '305px', marginLeft: 0 }}>
              <MuiThemeProvider theme={GenerateGtinButton}>
                <Tooltip title={disabled ? '' : this.state.btnGtinTooltipTitle}>
                  <span>
                    <Button
                      disabled={
                        disabled ||
                        !isAvailabilityCreationGtin ||
                        !!gtin ||
                        !!generatedGtin ||
                        !this.groupFieldsFilled()
                      }
                      onClick={this.modalGcp}>
                      {t('Сгенерировать код упаковки')}
                    </Button>
                  </span>
                </Tooltip>
              </MuiThemeProvider>
            </ProductBlockNewRight>
          )}
        </ProductBlockNew>

        <MuiThemeProvider theme={CertSelectAuto}>
          <Autocomplete
            options={activeKzDictionary}
            autoHighlight
            onChange={(_, item) =>
              this.handleChange('CatId', item ? item.id : '')
            }
            getOptionLabel={(item) => item.name}
            popupIcon={<IconDropDownAuto />}
            disabled={!this.isReadySubmitGroup()}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('Код государственного классификатора')}
                variant="standard"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'disabled',
                }}
              />
            )}
            noOptionsText={t('Нет совпадений')}
          />
        </MuiThemeProvider>
        {/* <MuiThemeProvider theme={RedirectDialog}>
          <Dialog 
            open={this.state.isModalOpen}
            onClose={() => this.setState({ isModalOpen: false })}
            >
              <DialogContent>
                <div>{t('Данные карточки товара будут направлены в Национальные каталоги товаров Российской Федерации и Республики Беларусь в рамках трансграничной торговли и межоператорского взаимодействия')}
                </div>                
              </DialogContent>

              <DialogActions>
              <Button  color={'primary'} onClick={() => this.setState({ isModalOpen: false })}>{t('Закрыть')}</Button>
              </DialogActions>
          </Dialog>
        </MuiThemeProvider> */}
      </DialogContent>
    );
  };

  render() {
    const {
      t,
      modalType,
      profile,
      productId,
      loading,
      technicalOptions,
      technicalCategories,
      countries,
      isAccountCanCreateGtin,
      isAvailabilityCreationGtin,
      accountGcpList,
      generatedGtin,
      releaseFormList,
    } = this.props;
    const { company } = profile;
    const {
      editingIsPermitted,
      gtinValidation,
      regNumberValidation,
      goodDraftId,
      nestedArray,
      errors,
      openModalNewGtin,
      buttonCloseDisable,
      buttonSaveDisable,
      data,
      manufacturerFields,
      manufacturerChecked,
      producerFields,
      producerChecked,
      importerFields,
      importerChecked,
      groupPackageFields,
      nestedUndescribedCount,
      openGcpModal,
      currentCatName,
      isMarkGroupCategory
    } = this.state;
    const { gtin } = data;
    const technicalMode = modalType === 'technical';
    const requiredFields = checkRequiredFields(data, technicalMode);
  
    /**
     * переписать только под транспортную упаковку.
     * _.every принимает 0 и false как отсутствие value, поэтому в selectIsDuplicated:
     *  "1" - это true
     *  "0" - это false
     * жутковато.
     */
    const groupPackageHasDulicates = _.some(nestedArray, [
      'selectIsDuplicated',
      '1',
    ]);
    /**
     * проверки для групповой упаковки
     */
    const groupPackageRegisteredAdd = _.every(
      _.values(groupPackageFields.arrayData[0]),
    );
    const groupPackageUnregisteredAdd = _.every(
      _.values(groupPackageFields.nestedUnregisteredData[0]),
    );

    /**
     * проверка на доступность поля "Введите код упаковки" - должен быть заполнен хотя бы один блок.
     */
    const atleastOneFieldIsFilled =
      groupPackageRegisteredAdd ||
      groupPackageUnregisteredAdd ||
      !!nestedUndescribedCount;

    const isErrors = _.some(_.values(errors)) && _.isNil(goodDraftId);
    const disableButton =
      isErrors ||
      !requiredFields ||
      gtinValidation.process ||
      !_.isEmpty(gtinValidation.message) ||
      buttonSaveDisable;
    
    const disabledGTIN =
      !_.isNaN(productId) ||
      gtinValidation.process ||
      gtinValidation.successed ||
      !_.isNil(goodDraftId);

    const groupTransportDisabledGTIN =
      !_.isNaN(productId) || gtinValidation.successed || !_.isNil(goodDraftId);
      
    return (
      <MuiThemeProvider theme={NewProductDialog}>
        <Dialog open>
          {/* {openModalNewGtin && (
            <PopupNewGtin
              handleClose={this.modalNewGtin}
              returnGtin={this.returnGtin}
            />
          )} */}
          {openGcpModal && (
            <GcpModal
              handleClose={this.modalGcp}
              accountGcpList={accountGcpList}
              modalType={modalType}
              groupPackageFields={groupPackageFields}
              nestedArray={nestedArray}
              manufacturerFields={manufacturerFields}
            />
          )}
          <DialogTitle>
            <DialogProductTitleContent className="newProduct">
              <DialogTitleStyled>{this.titleModal()}</DialogTitleStyled>
              <MuiThemeProvider theme={NewCloseBtns}>
                <Button
                  disabled={buttonCloseDisable}
                  onClick={() => this.handleClose(data, true)}>
                  <IconClose />
                </Button>
              </MuiThemeProvider>
            </DialogProductTitleContent>
          </DialogTitle>
          {this.renderModalInfo(requiredFields)}
          {this.state.showCopyAlert && (
            <CopyProductAlert
              onConfirm={this.confirmAlertModal}
              onCancel={this.cancelAlertModal}
            />
          )}
          {modalType === 'unit' && (
            <PopupDialogContent
              key={producerFields.length}
              disabledGTIN={disabledGTIN}
              handleChange={this.handleChange}
              editingIsPermitted={editingIsPermitted}
              onManufacturerImporterSelectChange={
                this.handleManufacturerImporterSelectChange
              }
              manufacturerChecked={manufacturerChecked}
              manufacturerFields={manufacturerFields}
              producerFields={producerFields}
              producerChecked={producerChecked}
              onProducerSelectChange={this.handleProducerSelectChange}
              updateConsumerItem={this.updateConsumerItem}
              handleErrors={this.handleErrors}
              onValidateCensorship={this.handleValidateCensorship}
              sendSubaccount={this.sendSubaccount}
              addItem={this.addItem}
              deleteItem={this.deleteItem}
              importerChecked={importerChecked}
              importerFields={importerFields}
              technicalMode={technicalMode}
              fieldsFilled={this.fieldsFilled}
              data={data}
              gtinValidation={gtinValidation}
              regNumberValidation={regNumberValidation}
              errors={errors}
              gtin={gtin}
              company={company}
              countries={countries}
              isAccountCanCreateGtin={isAccountCanCreateGtin}
              modalGcp={this.modalGcp}
              checkGtin={this.checkGtin}
              btnGtinTooltipTitle={this.state.btnGtinTooltipTitle}
              currentCatName={this.state.currentCatName}
              releaseFormList={this.props.releaseFormList}
              isModalOpen={this.state.isModalOpen}
              isMarkGroupCategory={this.state.isMarkGroupCategory}
            />
            
          )}
          {modalType === 'unit' && (
                <MuiThemeProvider theme={RedirectDialog}>
                  <Dialog
                    open={this.state.isModalOpen}
                    onClose={() => this.setState({ isModalOpen: false })}
                  >
                    <DialogContent>
                      <div>
                        {t(
                          'Данные карточки товара будут направлены в Национальные каталоги товаров Российской Федерации и Республики Беларусь в рамках трансграничной торговли и межоператорского взаимодействия'
                        )}
                      </div>
                    </DialogContent>

                    <DialogActions>
                      <Button
                        color={'primary'}
                        onClick={() => this.setState({ isModalOpen: false })}
                      >
                        {t('Закрыть')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </MuiThemeProvider>
              )}
          {modalType === 'group' &&
            this.renderDialogGroupContent(
              groupTransportDisabledGTIN,
              atleastOneFieldIsFilled,
            )}

          {modalType === 'transport' && (
            <DialogContent>
              <DialogTitleOfContentStyled>
                {' '}
                {t('Вложенные зарегистрированные товары (Опубликованные)')}{' '}
              </DialogTitleOfContentStyled>
              {this.renderGroupPackageFields(nestedArray, modalType)}
              <ProductBlockNew>
                <ProductBlockNewLeft>
                  <MuiThemeProvider theme={defaultTheme}>
                    <InputContainer>
                      <CustomInput
                        id="transportAndGroupGtin"
                        name="gtin"
                        type="text"
                        maxLength={14}
                        required={true}
                        disabled={
                          errors.nestedArrayErrros ||
                          !this.groupAndTrasportFiledsFilled() ||
                          groupTransportDisabledGTIN ||
                          groupPackageHasDulicates
                        }
                        label={
                          !data.gtin
                            ? t('Введите код упаковки')
                            : errors.gtin
                            ? errors.gtin
                            : !_.isEmpty(gtinValidation.message)
                            ? t('Введите корректный код упаковки')
                            : t('Код упаковки')
                        }
                        onUpdate={() => {}}
                        onChange={(value) => this.handleChange('gtin', value)}
                        value={
                          groupTransportDisabledGTIN || !editingIsPermitted
                            ? packageGtinDisplay(_.defaultTo(gtin, ''))
                            : _.defaultTo(gtin, '')
                        }
                        error={
                          !!errors.gtin || !_.isEmpty(gtinValidation.message)
                        }
                        valid={gtinValidation.successed}
                      />
                      {errors.gtin ? (
                        <InputIcon>
                          <IconError />
                        </InputIcon>
                      ) : !_.isEmpty(gtinValidation.message) ? (
                        SimpleTooltip(gtinValidation.message, <IconError />)
                      ) : gtinValidation.process ? (
                        <InputIcon>
                          <IconPreloader />
                        </InputIcon>
                      ) : gtinValidation.successed ? (
                        <InputIcon>
                          <IconValid />
                        </InputIcon>
                      ) : (
                        SimpleTooltip(
                          t('Код товара состоит из 8, 9, 12, 13 или 14 цифр'),
                          <IconInfoTooltip />,
                        )
                      )}
                    </InputContainer>
                  </MuiThemeProvider>
                </ProductBlockNewLeft>
                {isAccountCanCreateGtin && (
                  <ProductBlockNewRight
                    style={{ width: '305px', marginLeft: 0 }}>
                    <MuiThemeProvider theme={GenerateGtinButton}>
                      <Tooltip
                        title={
                          !this.groupAndTrasportFiledsFilled()
                            ? ''
                            : this.state.btnGtinTooltipTitle
                        }>
                        <span>
                          <Button
                            disabled={
                              errors.nestedArrayErrros ||
                              !this.groupAndTrasportFiledsFilled() ||
                              groupTransportDisabledGTIN ||
                              groupPackageHasDulicates ||
                              !isAvailabilityCreationGtin ||
                              !!gtin ||
                              !!generatedGtin
                            }
                            onClick={this.modalGcp}>
                            {t('Сгенерировать код упаковки')}
                          </Button>
                        </span>
                      </Tooltip>
                    </MuiThemeProvider>
                  </ProductBlockNewRight>
                )}
              </ProductBlockNew>
            </DialogContent>
          )}

          {modalType === 'technical' && (
            <DialogContent>
              <PopupNewTechnicalCard
                technicalOptions={technicalOptions}
                technicalCategories={technicalCategories}
                updateItem={this.updateItem}
                banOnSaving={this.banOnSaving}
                handleChange={this.handleChange}
                data={this.state.data}
                gtinValidation={this.state.gtinValidation}
              />
              <ProductBlockNew>
                <ProductBlockNewLeft>
                  <MuiThemeProvider theme={defaultTheme}></MuiThemeProvider>
                </ProductBlockNewLeft>
              </ProductBlockNew>
            </DialogContent>
          )}

          {modalType === 'technicalUnmarked' && (
            <DialogContent>
              <PopupNewTechnicalUnmarkedCard
                data={this.state.data}
                handleChange={this.handleChange}
              />
              <ProductBlockNew>
                <ProductBlockNewLeft>
                  <MuiThemeProvider theme={defaultTheme}></MuiThemeProvider>
                </ProductBlockNewLeft>
              </ProductBlockNew>
            </DialogContent>
          )}

          <DialogActions>
            <Button
              onClick={this.createNewConsumerCard}
              disabled={this.switchModalType() ||              
                isMarkGroupCategory ? !data.regNumber || !data.releaseForm : !data.CatId 

              // //   modalType === 'unit' || modalType === 'group' ? !data.CatId : !this.isReadySubmitGroupTransport() ||
              // //   modalType === 'technical' ? !data.gtin : data.CatId              
              }
            >
              &nbsp;
              {disableButton && loading ? <IconPreloader /> : t('Подтвердить')}
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  accountingSystem: _.get(
    state.authentificationReducer,
    'user.accountingSystem',
    null,
  ),
  loading:
    !!state.catalogReducer.loading || !!state.productReducer.loadingIsData,
  gtinsListByPackageType: state.catalogReducer.gtinsListByPackageType,
  activeKzDictionary: state.catalogReducer.activeKzDictionary,
  profile: state.profileReducer.info,
  profileStatusData: state.profileStatusReducer.data,
  subaccountData: state.subAccountReducer.subaccountsForGoodsCreation,
  gtinsListWasLoaded: state.catalogReducer.gtinsListWasLoaded,
  gtinsListForValidation: state.catalogReducer.gtinsListForValidation,
  gtinsForValidationWasLoaded: state.catalogReducer.gtinsForValidationWasLoaded,
  productData: state.productReducer.data,
  technicalOptions: state.productReducer.technicalOptions,
  technicalCategories: state.productReducer.technicalCategories,
  countries: state.productReducer.countries,
  isAccountCanCreateGtin: state.productReducer.isAccountCanCreateGtin,
  accountGcpList: state.productReducer.accountGcpList,
  isAvailabilityCreationGtin: state.productReducer.isAvailabilityCreationGtin,
  generatedGtin: state.productReducer.generatedGtin,  
  releaseFormList: state.productReducer.releaseFormList,  
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      registrationNumber: (regNumber, cbk) => registrationNumber(regNumber, cbk),
      markGroupCategory: (data, cbk) => markGroupCategory(data, cbk),
      validateGtin: (data, cbk) => validateGtin(data, cbk),
      validateGroupAndTransportGtin: (data, cbk) =>
        validateGroupAndTransportGtin(data, cbk),
      createConsumerPackaging: (data, cbk) =>
        createConsumerPackaging(data, cbk),
      loadModal: (productId, cbk) => loadModal(productId, cbk),
      throwError: (message) => throwNotification(message, 'error'),
      loadGtinsByPackageDictionary: (data) =>
        loadGtinsByPackageDictionary(data),
      onLoadProfileInfo: (cbk) => loadProfileInfo(cbk),
      onLoadProfileStatus: () => loadProfileStatus(),
      onLoadSubAccountData: () => loadSubAccount(),
      loadActiveKzDictionary: () => loadActiveKzDictionary(),
      sendSubAccount: (data, cbk) => sendSubAccount(data, cbk),
      clearGtinsListByPackage: () => clearGtinsListByPackage(),
      loadValidationList: () => loadGtinsListForValidation(),
      validateUnregistredGtin: (data, cbk) =>
        validateUnregistredGtin(data, cbk),
      clearProductData: () => clearProductData(),
      loadTechnicalOptions: () => loadTechnicalOptions(),
      loadTechnicalCategories: () => loadTechnicalCategories(),
      createTechnicalPackaging: (data, cbk) =>
        createTechnicalPackaging(data, cbk),
      validateTechnicalCard: (data, cbk) => validateTechnicalCard(data, cbk),
      loadCountries: () => loadCountries(),
      checkAccountCanCreateGtin: () => checkAccountCanCreateGtin(),
      loadAccountGcpList: () => loadAccountGcpList(),
      checkAvailabilityCreationGtin: (data, cbk) =>
        checkAvailabilityCreationGtin(data, cbk),
      clearAvailabilityCreationGtin: () => clearAvailabilityCreationGtin(),
      clearGeneratedGtin: () => clearGeneratedGtin(),
      clearReleaseFormList: () => clearReleaseFormList(),
    },
    dispatch,
  );
};

PopupNewProduct.propTypes = {
  handleClose: PropTypes.func.isRequired,
  createConsumerPackaging: PropTypes.func.isRequired,
  validateGtin: PropTypes.func.isRequired,
  validateGroupAndTransportGtin: PropTypes.func.isRequired,
  validateUnregistredGtin: PropTypes.func.isRequired,
  loadGtinsByPackageDictionary: PropTypes.func.isRequired,
  throwError: PropTypes.func.isRequired,
  loadDraftFromRequiredChanges: PropTypes.func.isRequired,
  loadModal: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  accountingSystem: PropTypes.string,
  modalType: PropTypes.oneOf([
    'unit',
    'kit',
    'technical',
    'group',
    'transport',
  ]),
  brands: PropTypes.array,
  productId: PropTypes.number,
  status: PropTypes.string,
  profile: PropTypes.object.isRequired,
  profileStatusData: PropTypes.array.isRequired,
  onLoadProfileInfo: PropTypes.func.isRequired,
  onLoadProfileStatus: PropTypes.func.isRequired,
  onLoadSubAccountData: PropTypes.func.isRequired,
  subaccountData: PropTypes.array.isRequired,
  sendSubAccount: PropTypes.func.isRequired,
  loadActiveKzDictionary: PropTypes.func.isRequired,
  clearGtinsListByPackage: PropTypes.func.isRequired,
  clearProductData: PropTypes.func.isRequired,
  loadTechnicalOptions: PropTypes.func.isRequired,
  loadTechnicalCategories: PropTypes.func.isRequired,
  createTechnicalPackaging: PropTypes.func.isRequired,
  validateTechnicalCard: PropTypes.func.isRequired,
  loadCountries: PropTypes.func.isRequired,
  checkAccountCanCreateGtin: PropTypes.func.isRequired,
  loadAccountGcpList: PropTypes.func.isRequired,
  checkAvailabilityCreationGtin: PropTypes.func.isRequired,
  clearAvailabilityCreationGtin: PropTypes.func.isRequired,
  clearGeneratedGtin: PropTypes.func.isRequired,
  registrationNumber: PropTypes.func.isRequired,
  clearReleaseFormList: PropTypes.func.isRequired,
  markGroupCategory: PropTypes.func.isRequired,
};

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(PopupNewProduct);
