import * as React from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  difference,
  forEach,
  includes,
  isEmpty,
  isEqual,
  map,
  reject,
  size,
  some,
  union,
} from 'lodash';
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Select,
  Tooltip,
} from '@material-ui/core';
import {
  IconArrowUp,
  IconSelectDropDown,
} from '../../../../common/icons/customIcons';
import {
  GridActionSelectBtn,
  GridActionsSelect,
} from './ProductsGridActionSelect.styled';

/* Новые
1 Просмотр 0 1 2 3 4 5
2 Удалить 7
3 Редактировать 0 2 7
4 Открыть черновик 3 4 5
5 На модерацию (отправить) 0 2
6 Подписать и опубликовать 3
7 Опубликовать без подписи 3
8 Экспорт 0 1 2 3 4 5 6 7

-----
Привязка действий к конкретному статусу карточек:
Требует обработки 2 3 8
Черновик 1 2 3 5 8
На модерации 1 8
Требует изменений 1 3 5 8
Ожидает подписания 1 4 6 7 8
Опубликована 1 4 8

{ id: 0, title: "Черновик" },
{ id: 1, title: "На модерации" },
{ id: 2, title: "Требует изменений" },
{ id: 3, title: "Ожидает подписания" },
{ id: 4, title: "Опубликована (с УКЭП)" },
{ id: 5, title: "Опубликована (без УКЭП)" },
{ id: 6, title: "В архиве" },
{ id: 7, title: "Требует обработки" },
 */

class ProductsGridActionSelect extends React.Component {
  shouldComponentUpdate(nextProps) {
    const equal = [];
    const nameProps = ['ids', 'statuses', 'locale', 'isEklUser'];
    forEach(nameProps, (i) =>
      equal.push(!isEqual(this.props[i], nextProps[i])),
    );
    return some(equal);
  }

  renderMenuOptions = (
    ids,
    moreStatus,
    statuses,
    actions,
    canBeDeletedAsGroup,
  ) => {
    if (!moreStatus) {
      return map(actions, (action) => {
        if (difference(statuses, action.statuses).length === 0) {
          return (
            <MenuItem
              key={`s_${ids}_${action.id}`}
              disableGutters={true}
              disabled={action.disabled}>
              <Button onClick={action.onClick}>
                {this.props.t(action.label)}
              </Button>
            </MenuItem>
          );
        }
      });
    }

    if (canBeDeletedAsGroup) {
      return (
        <MenuItem
          key={`s_${ids}_${actions[0].id}`}
          disableGutters={true}
          disabled={actions[0].disabled}>
          <Button onClick={actions[0].onClick}>
            {this.props.t(actions[0].label)}
          </Button>
        </MenuItem>
      );
    }

    return null;
  };

  render() {
    const {
      onModerationRecall,
      onDelete,
      onSendForModeration,
      onDraft,
      doubleStatus,
      isRemainder,
      statuses,
      ids,
      isEklUser,
      t,
    } = this.props;

    let actions;

    if (!includes(isRemainder, 1)) {
      actions = [
        {
          id: 1,
          label: 'Удалить',
          onClick: onDelete,
          statuses: [0, 2, 3, 7],
          disabled: false,
        },
        {
          id: 3,
          label: 'Открыть черновик',
          onClick: onDraft,
          statuses: [4, 5],
          disabled: false,
        },
        {
          id: 4,
          label: 'На модерацию',
          onClick: onSendForModeration,
          statuses: [0, 2],
          disabled: false,
        },
        {
          id: 5,
          label: 'Подписать и опубликовать',
          onClick: this.props.onPubSigned,
          statuses: [3],
          disabled: !isEklUser,
        },
        {
          id: 6,
          label: 'Отозвать из модерации',
          onClick: onModerationRecall,
          statuses: [1, 8],
          disabled: false,
        },
        // {id: 6, label: 'Опубликовать без подписи', onClick: onPubNotsigned, statuses: [3], disabled: false},
        // {id: 7, label: 'Отправить в архив', onClick: onArchive, statuses: [4, 5], disabled: false},
      ];

      // if (activePubNotSigned) {
      //     actions.push({
      //         id: 6,
      //         label: 'Опубликовать без подписи',
      //         onClick: onPubNotsigned,
      //         statuses: [3],
      //         disabled: false
      //     })
      // }

      if (doubleStatus) {
        actions = reject(actions, { id: 3 });
      }
    }

    /**
     * Для того чтобы можно было удалить карточки со смешанными статусами.
     * Но только у статусов - черновик, требует изменений, ожидает подписания.
     *
     * @statusesCanBeDeleted по сути дублирует разрешенные статусы для удаления, взятые из action[0].statuses
     *
     * @canBeDeletedAsGroup проверяет, что в массиве выделенных карточек(statuses) нет тех, которые нельзя удалять.
     */
    const statusesCanBeDeleted = [0, 2, 3, 7];
    const canBeDeletedAsGroup =
      !isEmpty(statuses) &&
      statuses.every((elem) => statusesCanBeDeleted.indexOf(elem) >= 0);

    const style = !isEmpty(ids) ? {} : { pointerEvents: 'none' };
    const statusesUnion = union(statuses);
    const sizeUnion = size(statusesUnion);

    // если статус опубликовано с УКЭП и без УКЭП считаем, что статус одинаковый
    const moreStatus =
      !(
        sizeUnion === 2 &&
        includes(statusesUnion, 4) &&
        includes(statusesUnion, 5)
      ) && sizeUnion > 1;

    const disabled = isEmpty(ids);

    return (
      <MuiThemeProvider theme={GridActionsSelect}>
        <Tooltip
          placement="bottom-end"
          title={
            moreStatus && !canBeDeletedAsGroup
              ? t(
                  'Для выполнения некоторых массовых действий необходимо выбрать карточки с одинаковым статусом',
                )
              : ''
          }>
          <FormControl fullWidth={true} disabled={disabled}>
            <InputLabel htmlFor="select-action">{t('Действие')}</InputLabel>
            <Select
              style={style}
              input={<Input id="select-action" />}
              value=""
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
                transformOrigin: { vertical: 'top', horizontal: 'center' },
              }}
              IconComponent={IconSelectDropDown}>
              <MenuItem disableGutters={true} disabled>
                <MuiThemeProvider theme={GridActionSelectBtn}>
                  <Button>
                    {t('Действие')} <IconArrowUp />
                  </Button>
                </MuiThemeProvider>
              </MenuItem>
              {this.renderMenuOptions(
                ids,
                moreStatus,
                statuses,
                actions,
                canBeDeletedAsGroup,
              )}
            </Select>
          </FormControl>
        </Tooltip>
      </MuiThemeProvider>
    );
  }
}

ProductsGridActionSelect.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onSendForModeration: PropTypes.func.isRequired,
  onModerationRecall: PropTypes.func.isRequired,
  onPubSigned: PropTypes.func,
  onPubNotsigned: PropTypes.func.isRequired,
  onDraft: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  doubleStatus: PropTypes.bool.isRequired,
  ids: PropTypes.arrayOf(PropTypes.number),
  statuses: PropTypes.array,
  locale: PropTypes.string.isRequired,
  isRemainder: PropTypes.arrayOf(PropTypes.number),
};

const mapStateToProps = (state) => ({
  locale: state.profileReducer.locale,
});

export default compose(
  withTranslation(),
  connect(mapStateToProps),
)(ProductsGridActionSelect);
