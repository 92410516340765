import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button, Menu, MenuItem, MuiThemeProvider } from '@material-ui/core';
import { IconMenu } from '../../../../common/icons/customIcons';
import { findStatusObj } from '../../../../common/utils/utils';
import { GridActionsSelect } from './ProductsGridActionSelect.styled';
import { ButtonActionCont } from '../../../../common/styles/Common.styled';

class ProductsGridActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_.isEqual(this.props.original, nextProps.original) ||
      !_.isEqual(this.state, nextState)
    );
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      onPrint,
      onDelete,
      onEdit,
      onSendForModeration,
      onModerationRecall,
      onDraft,
      onViewSite,
      original,
      accountTypes,
      t,
      onPubSigned,
      isEklUser,
    } = this.props;
    const { id, status } = original.dto;
    const isExemplar = _.get(original.goods, 'isExemplar', 0);

    let actions;
    if (accountTypes.some(elem => elem === ('producer' || 'importer'))) {
      // _.includes(['producer', 'importer'], accountType)) {
      actions = [
        {
          id: 0,
          label: 'Печать',
          onClick: onPrint,
          statuses: [0, 1, 2, 3, 4, 5],
          disabled: false,
        },
        {
          id: 1,
          label: 'Удалить',
          onClick: onDelete,
          statuses: [0, 2, 3, 7],
          disabled: false,
        },
        {
          id: 2,
          label: 'Редактировать',
          onClick: onEdit,
          statuses: [0, 2, 7],
          disabled: false,
        },
        {
          id: 3,
          label: 'Открыть черновик',
          onClick: onDraft,
          statuses: [4, 5],
          disabled: false,
        },
        {
          id: 4,
          label: 'На модерацию',
          onClick: onSendForModeration,
          statuses: [0, 2],
          disabled: false,
        },
        {
          id: 5,
          label: 'Подписать и опубликовать',
          onClick: onPubSigned,
          statuses: [3],
          disabled: !isEklUser,
        },
        {
          id: 6,
          label: 'Отозвать из модерации',
          onClick: onModerationRecall,
          statuses: [1, 8],
          disabled: false,
        },
        // {id: 6, label: 'Опубликовать без подписи', onClick: onPubNotsigned, statuses: [3], disabled: false},
        // {id: 7, label: 'Отправить в архив', onClick: onArchive, statuses: [4, 5], disabled: false},
      ];

      // if (activePubNotSigned) {
      //     actions.push({
      //         id: 6,
      //         label: 'Опубликовать без подписи',
      //         onClick: onPubNotsigned,
      //         statuses: [3],
      //         disabled: false
      //     });
      // }
    } else {
      actions = [
        {
          id: 0,
          label: 'Открыть на сайте',
          onClick: onViewSite,
          statuses: [4, 5],
          disabled: false,
        },
      ];
    }

    const doubleStatus = !_.isNull(original.draft) && !_.isNull(original.goods);
    if (doubleStatus) {
      actions = _.reject(actions, { id: 3 });
    }

    const st = findStatusObj(status);

    return (
      <div>
        <ButtonActionCont>
          <Button
            onClick={this.handleClick}
            aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
            aria-haspopup="true">
            <IconMenu />
          </Button>
        </ButtonActionCont>

        <MuiThemeProvider theme={GridActionsSelect}>
          <Menu
            open={Boolean(this.state.anchorEl)}
            anchorEl={this.state.anchorEl}
            onClose={this.handleClose}
            id="simple-menu"
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
            {_.map(actions, (action) => {
              if (_.includes(action.statuses, st.id)) {
                return (
                  <MenuItem
                    key={`m_${id}_${action.id}`}
                    disableGutters={true}
                    onClick={this.handleClose}
                    disabled={action.disabled}>
                    <Button
                      onClick={() =>
                        action.onClick(id, original.nameDto, isExemplar)
                      }>
                      {t(action.label)}
                    </Button>
                  </MenuItem>
                );
              }
            })}
          </Menu>
        </MuiThemeProvider>
      </div>
    );
  }
}

ProductsGridActions.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSendForModeration: PropTypes.func.isRequired,
  onModerationRecall: PropTypes.func.isRequired,
  onPubSigned: PropTypes.func.isRequired,
  onPubNotsigned: PropTypes.func.isRequired,
  onDraft: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  onPrint: PropTypes.func.isRequired,
  onViewSite: PropTypes.func.isRequired,
  // accountType: PropTypes.string.isRequired,
  accountTypes: PropTypes.array.isRequired,
  original: PropTypes.object.isRequired,
};

export default withTranslation()(ProductsGridActions);
